import React from 'react';
import Proptypes from 'prop-types';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

export const Option = MenuItem;

export default function ComposedTextField (props) {
  const {
    variant = 'outlined',
    fullWidth = true,
    InputLabelProps = { shrink: true },
    max,
    min,
    maxLength,
  } = props;

  return (
    <TextField
      InputLabelProps={InputLabelProps}
      InputProps={{
        ...props.InputProps,
        inputProps: { min, max, maxLength },
      }}
      variant={variant}
      fullWidth={fullWidth}
      {...props}
    >
      {props.children}
    </TextField>
  );
}

ComposedTextField.propTypes = {
  min: Proptypes.string,
  max: Proptypes.string,
  inputRef: Proptypes.shape({}),
  children: Proptypes.node,
  InputProps: Proptypes.shape({}),
  variant: Proptypes.string,
  fullWidth: Proptypes.bool,
  InputLabelProps: Proptypes.shape({}),
  maxLength: Proptypes.number,
};
