import { createAsyncThunk } from '@reduxjs/toolkit';
import { setFilters, setSnackbarError, setSnackbarSuccess } from 'appRedux/actions';
import { getFormattedListData } from 'appRedux/utility';
import { getFormattedEmployeeActivityRequestBody, getFormattedEmployeeActivity } from './selector';

export const requestCreateEmployeeActivity = createAsyncThunk(
  'employeeActivity/createEmployeeActivity',
  async (details, { extra, dispatch, getState }) => {
    const { projects, orders } = getState();
    try {
      const requestBody = getFormattedEmployeeActivityRequestBody(details);
      const response = await extra.axios.post('/api/v3/employee_activity', requestBody);
      dispatch(setSnackbarSuccess('snackbar_employee_activity_created'));
      if (projects.showProjectDialog || orders.showOrderDialog) {
        return response.data.data;
      }
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_employee_activity_create_failed'));
    }
  },
);

export const requestUpdateEmployeeActivity = createAsyncThunk(
  'employeeActivity/updateEmployeeActivity',
  async (details, { extra, dispatch }) => {
    try {
      const requestBody = getFormattedEmployeeActivityRequestBody(details);
      const response = await extra.axios.put(`/api/v3/employee_activity/${details.id}`, requestBody);
      dispatch(setSnackbarSuccess('snackbar_employee_activity_updated'));
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_employee_activity_update_failed'));
    }
  },
);

export const requestEmployeeActivity = createAsyncThunk(
  'employeeActivity/fetchEmployeeActivity',
  async (activityId, { extra, dispatch }) => {
    try {
      const response = await extra.axios.get(`/api/v3/employee_activity/${activityId}`);
      const formattedData = getFormattedEmployeeActivity(response.data.data);
      return { employeeActivity: formattedData };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_employee_activity_fetch_failed'));
    }
  },
);

export const requestDeleteEmployeeActivity = createAsyncThunk(
  'employeeActivity/deleteEmployeeActivity',
  async (activityId, { extra, dispatch }) => {
    try {
      await extra.axios.delete(`/api/v3/employee_activity/${activityId}`);
      dispatch(setSnackbarSuccess('snackbar_employee_activity_deleted'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_employee_activity_delete_failed'));
    }
  },
);

export const requestDeleteEmployeeActivities = createAsyncThunk(
  'employeeActivity/deleteRecords',
  async (selectedIds, { extra, dispatch }) => {
    try {
      const body = { ids: selectedIds };
      await extra.axios.delete('/api/v3/employee_activity/bulk_delete', { data: body });
      dispatch(setSnackbarSuccess('snackbar_employee_activity_deleted'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_employee_activity_delete_failed'));
    }
  },
);

export const requestEmployeeActivities = createAsyncThunk(
  'employeeActivity/fetchEmployeeActivities',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = {
        page: filters.page || 1,
        per_page: filters.perPage,
        query: filters.query,
      };
      const response = await extra.axios.get('/api/v3/employee_activity', { params });
      const { records, ...nextFilters } = getFormattedListData(response.data.data);
      dispatch(setFilters({ ...nextFilters, name }));
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_employee_activity_get_failed'));
    }
  },
);
