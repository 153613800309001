import { createAsyncThunk } from '@reduxjs/toolkit';
import { setSnackbarError, setSnackbarSuccess } from 'appRedux/actions';
import { getStandardDate } from 'utils/dateTime';
import { get } from 'utils/lodash';
import { fm } from 'utils/string';
import {
  validateAndGetFormattedSalaryCode,
  validateAndGetFormattedShiftTime,
  validateAndGetFormattedExtraTimeBody,
  validateAndGetFormattedApproveTimeBody,
} from './validator';
import {
  getFormattedDayShiftData,
  getFormattedEmployeeDayShifts,
  getFormattedDayShiftPunchInBody,
  getFormattedDayShiftPunchOutBody,
  getFormattedPunchedData,
  getFormattedShiftData,
  getFormattedShiftTimesData,
} from './selector';

export const requestTodaysShiftDetails = createAsyncThunk(
  'timeManagement/fetchTodaysShiftDetails',
  async (employeeId, { extra, rejectWithValue, dispatch }) => {
    try {
      const params = { employee_id: employeeId, added_date: getStandardDate() };
      const response = await extra.axios.get('/api/v3/shift_times/per_day_time', { params });
      const dayShift = getFormattedDayShiftData(response.data.data);
      return dayShift;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_get_today_shift_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestTimeManagementDayShift = createAsyncThunk(
  'timeManagement/punchInTodaysShift',
  async (dayShiftId, { extra, rejectWithValue, dispatch }) => {
    try {
      const response = await extra.axios.get(`/api/v3/shift_times/${dayShiftId}`);
      const dayShift = getFormattedDayShiftData(response.data.data);
      return dayShift;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_get_shft_detail_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestEmployeeShifts = createAsyncThunk(
  'timeManagement/fetchEmployeeShifts',
  async (data, { extra, rejectWithValue, dispatch }) => {
    try {
      const params = {
        employee_id: data.employeeId,
        added_date: getStandardDate(data.addedOn),
      };
      const response = await extra.axios.get('/api/v3/shift_times/employee_shifts', { params });
      const employeeDayShifts = getFormattedEmployeeDayShifts(response.data.data.records);
      return employeeDayShifts;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_get_employee_shift_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestPunchInTodaysShift = createAsyncThunk(
  'timeManagement/punchInTodaysShift',
  async (data, { extra, rejectWithValue, dispatch }) => {
    try {
      const reqBody = getFormattedDayShiftPunchInBody(data);
      const punchReponse = await extra.axios.post('/api/v3/shift_times', reqBody);
      const dayShiftId = punchReponse.data.data.id;
      dispatch(requestTimeManagementDayShift(dayShiftId));
      dispatch(requestEmployeeShifts(data));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_employeeView_punch_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestPunchOutTodaysShift = createAsyncThunk(
  'timeManagement/punchOutTodaysShift',
  async (data, { extra, rejectWithValue, dispatch }) => {
    try {
      const reqBody = await getFormattedDayShiftPunchOutBody(data);
      await extra.axios.put(`/api/v3/shift_times/${data.punchId}`, reqBody);
      dispatch(requestTodaysShiftDetails(data.employeeId));
      dispatch(requestEmployeeShifts(data));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_get_per_day_time_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestUpdateSalaryCodeRecord = createAsyncThunk(
  'timeManagement/updateSalaryCodeRecord',
  async (shiftTime, { extra, rejectWithValue, dispatch }) => {
    try {
      const reqBody = await validateAndGetFormattedSalaryCode(shiftTime, false);
      await extra.axios.put(`/api/v3/shift_times/${shiftTime.id}`, reqBody);
      dispatch(requestEmployeeShifts({ employeeId: shiftTime.employeeId }));
      dispatch(setSnackbarSuccess('snackbar_salary_code_updated'));
      return { fsip: false, showEmployeeShiftTimeModal: false, shiftTime: {} };
    } catch (err) {
      if (err.name === 'ValidationError') {
        return { shiftTimeModalErrors: { [err.path]: err.errors[0] } };
      }
      dispatch(setSnackbarError('snackbar_salary_code_update_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestCreateSalaryCodeRecord = createAsyncThunk(
  'timeManagement/createSalaryCodeRecord',
  async (shiftTime, { extra, rejectWithValue, dispatch }) => {
    try {
      const reqBody = await validateAndGetFormattedSalaryCode(shiftTime, true);
      await extra.axios.post('/api/v3/salary_details', reqBody);
      dispatch(setSnackbarSuccess('snackbar_salary_code_created'));
      return { showEmployeeShiftTimeModal: false, shiftTime: {} };
    } catch (err) {
      if (err.name === 'ValidationError') {
        const shiftTimeModalErrors = { [err.path]: err.errors[0] };
        return { shiftTimeModalErrors };
      }
      dispatch(setSnackbarError('snackbar_salary_code_create_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestUpdateExtraTimeRecord = createAsyncThunk(
  'timeManagement/updateExtraTimeRecord',
  async (shiftTime, { extra, rejectWithValue, dispatch, getState }) => {
    try {
      const { employee: { profile: { company, user } } } = getState();
      const reqBody = await validateAndGetFormattedExtraTimeBody(shiftTime, company.breakTimeSettings);
      await extra.axios.put(`/api/v3/shift_times/${shiftTime.shiftId}`, reqBody);
      dispatch(requestEmployeeShifts({ employeeId: user.id }));
      dispatch(setSnackbarSuccess('snackbar_shift_time_updated'));
      return { showEmployeeShiftTimeModal: false, shiftTime: {} };
    } catch (err) {
      if (err.name === 'ValidationError') {
        return { shiftTimeModalErrors: { [err.path]: err.errors[0] } };
      }
      dispatch(setSnackbarError('snackbar_shift_time_update_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestShiftDetails = createAsyncThunk(
  'timeManagement/fetchShiftDetails',
  async (details, { extra, rejectWithValue, dispatch }) => {
    try {
      const response = await extra.axios.get(`/api/v3/shift_times/${details.id}`);
      return details.punchedShift
        ? getFormattedPunchedData(response.data.data)
        : getFormattedShiftData(response.data.data);
    } catch (err) {
      dispatch(setSnackbarError('snackbar_get_shft_detail_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestApproveShiftTime = createAsyncThunk(
  'timeManagement/approveShiftDetails',
  async (data, { extra, rejectWithValue, dispatch }) => {
    try {
      const reqBody = await validateAndGetFormattedApproveTimeBody(data);
      await extra.axios.put(`/api/v3/shift_times/${data.shiftId}`, reqBody);
      dispatch(requestTimeManagementDayShift(data.shiftId));
      dispatch(setSnackbarSuccess('snackbar_employee_view_approved_shift_time_success'));
      return { showEmployeeShiftTimeModal: false };
    } catch (err) {
      if (err.name === 'ValidationError') {
        return { timeReportError: { [err.path]: err.errors[0] } };
      }
      dispatch(setSnackbarError('snackbar_employee_view_approved_shift_time_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestDeleteShiftTime = createAsyncThunk(
  'timeManagement/deleteShiftTime',
  async (id, { extra, rejectWithValue, dispatch }) => {
    try {
      await extra.axios.delete(`/api/v3/shift_times/${id}`);
      dispatch(setSnackbarSuccess('snackbar_employee_view_remove_shift_time_success'));
      return { deletedShiftId: id };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_employee_view_remove_shift_time_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestShiftTimes = createAsyncThunk(
  'timeManagement/fetchShiftTimes',
  async (filters, { extra, rejectWithValue, dispatch }) => {
    try {
      const params = {
        start_date: filters.startDate,
        end_date: filters.endDate,
      };
      const response = await extra.axios.get('/api/v3/shift_times', { params });
      const { records, totals } = getFormattedShiftTimesData(response.data.data.shiftTimes);
      return { records, totals };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_get_shift_time_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestCreateExtraTimeRecord = createAsyncThunk(
  'timeManagement/createExtraTimeRecord',
  async (shiftTime, { extra, dispatch, getState }) => {
    try {
      const { employee: { profile: { company }, timeManagement: { filters } } } = getState();
      const reqBody = await validateAndGetFormattedShiftTime(shiftTime, company.breakTimeSettings);
      await extra.axios.post('/api/v3/shift_times/create_shift_code_detail', reqBody);
      dispatch(setSnackbarSuccess('snackbar_shift_time_created'));
      dispatch(requestShiftTimes(filters));
      return { showEmployeeShiftTimeModal: false, shiftTime: {} };
    } catch (err) {
      if (err.name === 'ValidationError') {
        const shiftTimeModalErrors = { [err.path]: err.errors[0] };
        return { shiftTimeModalErrors };
      }
      if (get('response.data.errors[0].details.code')(err)) {
        return { shiftTimeModalErrors: { timeCodeId: fm(get('response.data.errors[0].details.code')(err)) } };
      }
      dispatch(setSnackbarError('snackbar_shift_time_create_error'));
    }
  },
);

export const requestEmployeeShiftsRecords = createAsyncThunk(
  'timeManagement/fetchEmployeeShiftsRecords',
  async (data, { extra, rejectWithValue, dispatch, getState }) => {
    try {
      const { employee: { timeManagement: { employeeDayShiftsRecords = [] } } } = getState();
      const params = {
        employee_id: data.employeeId,
        added_date: getStandardDate(data.addedOn),
      };
      const response = await extra.axios.get('/api/v3/shift_times/employee_shifts', { params });
      const employeeDayShifts = getFormattedEmployeeDayShifts(response.data.data.records);
      const mergedRecords = [...employeeDayShiftsRecords, ...employeeDayShifts];
      return { employeeDayShiftsRecords: mergedRecords };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_get_employee_shift_error'));
      return rejectWithValue(err);
    }
  },
);
