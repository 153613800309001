import { groupBy } from 'utils/lodash';
import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from 'appRedux/api/axiosBaseQuery';

export const timeManagementApi = createApi({
  reducerPath: 'timeManagementApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['TimeCodes', 'SalaryCodes'],
  endpoints: (builder) => ({
    getTimeCodes: builder.query({
      providesTags: ['TimeCodes'],
      query: () => ({ url: '/api/v3/time_codes/active_codes', method: 'GET' }),
      transformResponse: (response, meta, arg) => ({
        activeTimeCodes: groupBy('codeType')(
          response.data.records.filter((timeCode) => timeCode.companyId === arg),
        ), // arg is companyId
        allActiveTimeCodes: groupBy('codeType')(response.data.records),
      }),
    }),
    getSalaryCodes: builder.query({
      providesTags: ['SalaryCodes'],
      query: () => ({ url: '/api/v3/salary_codes/active_codes', method: 'GET' }),
      transformResponse: (response, meta, arg) => ({
        activeSalaryCodes: response.data.records.filter(
          (salaryCode) => salaryCode.companyId === arg,
        ), // arg is companyId
        allActiveSalaryCodes: response.data.records,
      }),
    }),
  }),
});

export const {
  useLazyGetTimeCodesQuery,
  useGetTimeCodesQuery,
  useLazyGetSalaryCodesQuery,
  useGetSalaryCodesQuery,
} = timeManagementApi;
