import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  requestOrders,
  requestItems,
  requestOrder,
  requestVirtualOrder,
  requestConversations,
  requestAddOrderItem,
  requestUpdateOrderItem,
  requestDeleteOrderItem,
  requestAddOrderEmployeeActivity,
  requestEditOrderEmployeeActivity,
} from './thunk';

const orders = createSlice({
  name: 'orders',
  initialState: {
    records: {},
    order: {},
    conversations: {
      records: [],
    },
    item: {},
    itemSuggestion: {
      records: [],
    },
    employeeActivityJunctions: [],
    employeeActivityJunction: {},
    employeeActivity: {},
  },
  reducers: {
    setOrderFields: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(requestOrders.fulfilled, (state, action) => ({
      ...state,
      records: action.payload.records,
    }));
    builder.addCase(requestItems.fulfilled, (state, action) => ({
      ...state,
      itemSuggestion: {
        ...state.itemSuggestion,
        records: action.payload.records,
      },
    }));
    builder.addCase(requestConversations.fulfilled, (state, action) => ({
      ...state,
      conversations: {
        records: action.payload.records,
      },
    }));
    builder.addMatcher(
      isAnyOf(requestOrder.fulfilled, requestVirtualOrder.fulfilled),
      (state, action) => ({
        ...state,
        order: action.payload,
      }),
    );
    builder.addMatcher(
      isAnyOf(requestAddOrderEmployeeActivity.fulfilled, requestEditOrderEmployeeActivity.fulfilled),
      (state) => ({
        ...state,
        showNewActivityDialog: false,
        employeeActivity: {},
      }),
    );
    builder.addMatcher(
      isAnyOf(requestAddOrderItem.fulfilled, requestUpdateOrderItem.fulfilled, requestDeleteOrderItem.fulfilled),
      (state) => ({
        ...state,
        showItemDetailsDialog: false,
      }),
    );
  },
});

const { reducer, actions } = orders;

export const { setOrderFields } = actions;

export default reducer;
