import { createAsyncThunk } from '@reduxjs/toolkit';
import { setFilters, setSnackbarError, setSnackbarSuccess } from 'appRedux/actions';
import { getFormattedListData } from 'appRedux/utility';
import { deepTrim } from 'utils/string';
import { getFormattedSkillRequestBody } from './selector';

export const requestSkills = createAsyncThunk(
  'skill/fetchSkills',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = { page: filters.page, per_page: filters.perPage, title: filters.query };
      const response = await extra.axios.get('/api/v3/skills', { params });
      const { records, ...nextFilters } = getFormattedListData(response.data.data.skill);
      dispatch(setFilters({ ...nextFilters, name }));
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_skills_get_failed'));
    }
  },
);

export const requestCreateSkill = createAsyncThunk(
  'skill/createSkill',
  async (details, { extra, dispatch }) => {
    try {
      const requestBody = deepTrim(getFormattedSkillRequestBody(details));
      const response = await extra.axios.post('/api/v3/skills', requestBody);
      dispatch(setSnackbarSuccess('snackbar_skill_created'));
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_skill_create_failed'));
    }
  },
);

export const requestUpdateSkill = createAsyncThunk(
  'skill/updateSkill',
  async (details, { extra, dispatch }) => {
    try {
      const requestBody = deepTrim(getFormattedSkillRequestBody(details));
      await extra.axios.put(`/api/v3/skills/${details.id}`, requestBody);
      dispatch(setSnackbarSuccess('snackbar_skill_updated'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_skill_update_failed'));
    }
  },
);

export const requestSkill = createAsyncThunk(
  'skill/fetchSkill',
  async (skillId, { extra, dispatch }) => {
    try {
      const response = await extra.axios.get(`/api/v3/skills/${skillId}`);
      const { skill } = response.data.data;
      return { record: skill };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_skill_fetch_failed'));
    }
  },
);

export const requestDeleteSkill = createAsyncThunk(
  'skill/deleteSkill',
  async (skillId, { extra, dispatch }) => {
    try {
      await extra.axios.delete(`/api/v3/skills/${skillId}`);
      dispatch(setSnackbarSuccess('snackbar_skill_deleted'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_skill_delete_failed'));
    }
  },
);

export const requestDeleteSkills = createAsyncThunk(
  'skill/deleteSkills',
  async (ids, { extra, dispatch }) => {
    try {
      const params = { skills: ids };
      await extra.axios.delete('/api/v3/skills/bulk_delete', { data: params });
      dispatch(setSnackbarSuccess('skills_deleted_successfully'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_skills_delete_error'));
    }
  },
);
