import { fm } from 'utils/string';
import { projectPriceTypes, getDefaultEmployeeEndtime } from 'components/Project/ProjectDrawer/utility';
import { getItemCost } from 'utils/getCost';
import { projectTimeTypesString } from 'utils/enum';
import {
  getHourMinutesInUnixTime,
  epochTimeToFloat,
  convertTimeStringToLocalString,
  addDays,
  formatDate,
  formatStrings,
} from 'utils/dateTime';
import { getTotalWorkingHoursForProjectOrderEmployees } from 'appRedux/utility';

export const orderDialogTabs = [
  {
    title: fm('order_details', 'Order'),
  },
  {
    title: fm('project_invoicing', 'Invoicing'),
  },
  {
    title: fm('project_checklist', 'Checklist'),
  },
];

export const orderTabsTypes = {
  orderDetails: 0,
  price: 1,
  checklist: 2,
};

export const activityTabsTypes = {
  activityDetails: 0,
  price: 1,
};

export const activityDialogTabs = [
  {
    title: fm('time_expense', 'Time / expense'),
  },
  {
    title: fm('item', 'Item'),
  },
];

export const employeeApproveTimeTypes = {
  punchTime: 'punch_time',
  shiftTime: 'shift_time',
};

export const getOrderItemsWithQuantityCalculated = (order, orderEmployees) => {
  const { priceType, orderItems = [] } = order;

  if (priceType !== projectPriceTypes.pricePerEmployee) return orderItems;
  const employeeInvoiceHourTotal = orderEmployees.reduce(
    (res, acc) => (acc.destroy ? res : res + Number(acc.invoiceTime ?? 0)),
    0,
  ) || 1;
  const roundedEmployeeInvoiceHourTotal = Number(employeeInvoiceHourTotal.toFixed(2));
  return orderItems.map((item) => {
    if (!item.perEmployee) return item;
    const updatedItem = { ...item, quantity: roundedEmployeeInvoiceHourTotal };
    return { ...updatedItem, ...getItemCost(updatedItem, order) };
  });
};

export const submitButtonTypes = {
  saveAndClose: 'saveClose',
  save: 'save',
};

export const orderActionTypes = {
  changeStatus: 'changeStatus',
  sendSms: 'sendSms',
  copyOrder: 'copyOrder',
  sendEmail: 'sendEmail',
  sendMessage: 'sendMessage',
  deleteOrder: 'deleteOrder',
  addTag: 'addTag',
  attachDocument: 'attachDocument',
  updateInvoiceId: 'updateInvoiceId',
};

export const getEmployeeFieldsInitializedForOrder = (employee, order) => {
  let startTime = order.startTime
    ? convertTimeStringToLocalString(order.startTime, order.date)
    : convertTimeStringToLocalString('08:00', order.date);
  let endTime = order.endTime
    ? convertTimeStringToLocalString(order.endTime, order.date)
    : getDefaultEmployeeEndtime(startTime);
  const breakTime = '00:00';

  if (new Date(startTime) > new Date(endTime)) {
    const endTimeDate = formatDate(addDays(order.date, 1), formatStrings.filtersDate);
    startTime = formatDate(startTime, formatStrings.defaulTime);
    endTime = formatDate(endTime, formatStrings.defaulTime);
    startTime = convertTimeStringToLocalString(startTime, order.date);
    endTime = convertTimeStringToLocalString(endTime, endTimeDate);
  }

  const paidTime = getTotalWorkingHoursForProjectOrderEmployees(startTime, endTime, breakTime);

  return {
    name: employee.name,
    userGroupTitle: employee.userGroupTitle,
    userGroupId: employee.userGroupId,
    colorCode: employee.colorCode,
    employeeId: employee.id || employee.userId,
    employee: { salaryType: employee.salaryType },
    sequenceNum: employee.sequenceNum,
    ...(order.timeType === projectTimeTypesString.specificTime && {
      startTime,
      endTime,
      breakTime,
      paidTime,
      invoiceTime: order.priceType === projectPriceTypes.pricePerEmployee
        ? epochTimeToFloat(getHourMinutesInUnixTime(paidTime)) : null,
    }),
    ignoreOvertime: false,
  };
};

export const openOrderRecipientsTabsTypes = {
  employeeRequests: 0,
  invitations: 1,
};

export const isEmployeeActivityOverlap = (startTime1, endTime1, startTime2, endTime2) => {
  const parsedStartTime1 = new Date(startTime1);
  const parsedEndTime1 = new Date(endTime1);
  const parsedStartTime2 = new Date(startTime2);
  const parsedEndTime2 = new Date(endTime2);

  if (parsedEndTime1.getTime() === parsedStartTime2.getTime()
    || parsedEndTime2.getTime() === parsedStartTime1.getTime()) {
    return false;
  }

  return parsedStartTime1 < parsedEndTime2 && parsedStartTime2 < parsedEndTime1;
};
