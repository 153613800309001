import { pick, isEmpty, omit, orderBy } from 'utils/lodash';
import { capitalize } from 'utils/string';
import { salaryPaidTypes } from 'components/EmployeeActivity/utility';
import { projectHouseworkTypes } from 'components/Project/ProjectDrawer/utility';
import {
  getFormattedPaginationFields,
  getFilterEmployeeId,
  getFilterCustomerId,
  getFilterServiceId,
} from 'appRedux/selectors/utility';
import { getStandardDate } from 'utils/helpers';
import { smsDataTypeAPI, smsDataType, orderStatus } from 'utils/enum';
import { getSmsDescription } from 'appRedux/thunks/utility';
import snakify from 'utils/snakify';
import { subDays } from 'date-fns';
import {
  formatDate,
  formatStrings,
  convertFloatToTimeString,
  timeStringToFloat,
  getStringToDate,
  convertTimeStringToLocalString,
  parseISO,
  addDays,
  convertISOStringToTime,
  getHourMinutesInUnixTime,
  getDifferenceBetweenTime,
} from 'utils/dateTime';
import { getTotalWorkingHoursForProjectOrderEmployees } from 'appRedux/utility';
import getItemsTotalCosts, { getItemCost } from 'utils/getCost';

const formatOrderEmployees = (orderEmployee) => {
  const startTime = orderEmployee.startTime ? new Date(orderEmployee.startTime.split('+')[0]) : null;
  const endTime = orderEmployee.endTime ? new Date(orderEmployee.endTime.split('+')[0]) : null;
  return {
    ...orderEmployee,
    startTime: startTime ? formatDate(startTime, formatStrings.defaulTime) : null,
    endTime: endTime ? formatDate(endTime, formatStrings.defaulTime) : null,
    invoiceTime: orderEmployee.invoiceTime ? timeStringToFloat(orderEmployee.invoiceTime) : null,
    breakTime: orderEmployee.breakTime ?? null,
  };
};

export const getFormattedOrderResponse = (response) => ({
  ...getFormattedPaginationFields(response),
  records: (response.records || []).map((record) => ({
    ...record,
    tags: (record.tags || []).map((tag) => ({
      ...tag,
      title: tag.title ?? tag.name,
    })),
    orderEmployees: (record.orderEmployees || []).map(formatOrderEmployees),
    id: record.orderId,
    sequenceNum: record.orderSequenceNum,
    virtual: !record.orderId,
  })),
});

export const formatOrderChecklistApiReq = (orderChecklists = [], orderId, companyId) => {
  let checklist = [];
  (orderChecklists || []).map((orderChecklist) => {
    checklist = [...checklist, ...orderChecklist.tasks.map((task) => ({
      id: orderId && task.orderTaskId,
      task_id: task.id,
      task_category_id: orderChecklist.taskCategory.id,
      _destroy: orderChecklist.taskCategory.destroy || task.destroy,
      completed: task.completed,
      order_number: task.orderNumber,
      company_id: companyId,
    }))];
    return checklist;
  });
  return checklist;
};

const formatOrderEmployeesRequestBody = (emp, order, company) => {
  let startTime;
  let endTime;
  if (emp.startTime && emp.endTime) {
    startTime = formatDate(emp.startTime, formatStrings.defaulTime);
    endTime = formatDate(emp.endTime, formatStrings.defaulTime);

    if (getHourMinutesInUnixTime(startTime) > getHourMinutesInUnixTime(endTime)) {
      const endTimeDate = formatDate(addDays(order.date, 1), formatStrings.filtersDate);
      startTime = convertTimeStringToLocalString(startTime, order.date);
      endTime = convertTimeStringToLocalString(endTime, endTimeDate);
    } else {
      startTime = convertTimeStringToLocalString(startTime, order.date);
      endTime = convertTimeStringToLocalString(endTime, order.date);
    }
  }

  return {
    employee_id: emp.employeeId,
    start_time: startTime || null,
    end_time: endTime || null,
    invoice_time: emp.invoiceTime === 0 ? '00:00' : emp.invoiceTime && (
      emp.invoiceTime !== '00:00' && emp.invoiceTime.includes(':')
        ? emp.invoiceTime : convertFloatToTimeString(emp.invoiceTime)),
    break_time: emp.breakTime,
    id: emp.id,
    order_id: order.id,
    _destroy: emp.destroy,
    company_id: company.id,
    invoiceable: emp.invoiceable || true,
    calculate_overtime: emp.calculateOvertime,
  };
};

export const getFormattedOrderRequestBody = (order, company) => ({
  update_invoice_time: order.updateInvoiceTime,
  customer_id: order.customerId || order.customer.id,
  service_id: order.serviceId,
  project_id: order.projectId,
  company_id: company.id,
  time_type: order.timeType,
  total_hours: order.totalHours,
  flex_time_type: order.flexTimeType,
  housework_type: order.houseworkType,
  published: order.published || false,
  start_time: order.startTime,
  end_time: order.endTime,
  date: order.date,
  status: order.status || orderStatus.active,
  original_date: order.originalDate || order.date,
  employee_confirmation_required: order.employeeConfirmationRequired || false,
  price_type: order.priceType,
  action_type: order.actionType,
  employee_presence_times_attributes: !isEmpty(order.orderEmployees)
    ? order.orderEmployees.map((emp) => formatOrderEmployeesRequestBody(emp, order, company))
    : [],
  order_items_attributes: order.orderItems && order.orderItems.map((item) => ({
    item_id: item.itemId,
    id: item.id,
    order_id: order.id,
    per_unit: item.perUnit,
    material_cost: item.materialCost,
    housework_type: item.houseworkType,
    is_housework: item.isHousework,
    account_number: item.accountNumber,
    discount: item.discount,
    discount_value: item.itemDiscount,
    per_employee: item.perEmployee,
    discount_type: item.discountType,
    ...(item.integrationDetail && {
      visma_item_id: item.integrationDetail.vismaId,
      material_cost: item.materialCost,
      fortnox_id: item.integrationDetail.fortnoxNumber,
    }),
    _destroy: item.destroy,
    company_id: company.id,
    ...pick(['quantity', 'title', 'vat', 'unit', 'hours'])(item),
  })),
  order_tags_attributes: order.orderTags && order.orderTags.map((tag) => ({
    id: tag.id,
    tag_id: tag.tagId,
    order_id: order.id,
    _destroy: tag.destroy,
    company_id: company.id,
  })),
  order_note_attributes: {
    id: order.orderNote?.id,
    order_id: order.id,
    notes: order.notes,
    company_id: company.id,
  },
  order_addresses_attributes: order.orderAddresses && order.orderAddresses.map((address) => ({
    id: address.id,
    order_id: order.id,
    title: address.title,
    address: address.address,
    _destroy: address.destroy,
    company_id: company.id,
  })),
  order_checklists_attributes: order.orderChecklists
    && formatOrderChecklistApiReq(order.orderChecklists, order.id, company.id),
  ...(order.bookingEbokaDetail && {
    order_eboka_detail_attributes: {
      id: order.bookingEbokaDetail.id,
      eboka_order_id: order.bookingEbokaDetail.ebokaOrderId,
    },
  }),
  employee_activity_junctions_attributes: (order.employeeActivityJunctions || []).map((activity) => ({
    id: activity.id,
    _destroy: activity.destroy,
    employee_activity_id: activity.employeeActivityId,
    title: activity.title,
    use_employee_time_code: activity.useEmployeeTimeCode,
    company_id: company.id,
    comment: activity.comment,
    invoiceable: activity.invoiceable,
    salary_paid: activity.salaryPaid,
    quantity: activity.quantity,
    paid_amount: activity.paidAmount,
    start_time: activity.startTime,
    end_time: activity.endTime,
    employee_id: activity.employeeId,
    code_id: activity.salaryPaid === salaryPaidTypes.time ? activity.timeCodeId : activity.salaryCodeId,
    code_type: activity.salaryPaid === salaryPaidTypes.expense ? 'SalaryCode' : 'TimeCode',
    employee_activity_junction_items_attributes: !isEmpty(activity.employeeActivityJunctionItem) ? [{
      title: activity.employeeActivityJunctionItem.title,
      quantity: activity.employeeActivityJunctionItem.quantity,
      id: activity.employeeActivityJunctionItem.id,
      vat: activity.employeeActivityJunctionItem.vat,
      unit: activity.employeeActivityJunctionItem.unit,
      per_unit: activity.employeeActivityJunctionItem.perUnit,
      incl_vat_per_unit: activity.employeeActivityJunctionItem.inclVatPerUnit,
      excl_vat_per_unit: activity.employeeActivityJunctionItem.exclVatPerUnit,
      housework_type: activity.employeeActivityJunctionItem.houseworkType,
      is_housework: activity.employeeActivityJunctionItem.isHousework,
      material_cost: activity.employeeActivityJunctionItem.materialCost,
      account_number: activity.employeeActivityJunctionItem.accountNumber,
      visma_item_id: activity.employeeActivityJunctionItem.vismaItemId,
      discount_type: activity.employeeActivityJunctionItem.discountType,
      discount: activity.employeeActivityJunctionItem.discount,
      fortnox_id: activity.employeeActivityJunctionItem.fortnoxId,
      item_id: activity.employeeActivityJunctionItem.itemId,
      calculate_quantity_dynamically: activity.employeeActivityJunctionItem.calculateQuantityDynamically,
      company_id: company.id,
      _destroy: activity.employeeActivityJunctionItem.destroy || activity.destroy,
    }] : undefined,
  })),
});

export const getFormattedScheduledData = (orderSchedule) => ({
  ...orderSchedule,
  messageText: orderSchedule.scheduledMessageDetail
    && getSmsDescription(orderSchedule.scheduledMessageDetail.messageText, smsDataType),
  deliveryTime: orderSchedule.startDate
    ? `${getStandardDate(
      subDays(getStringToDate(orderSchedule.startDate, 'yyyy-MM-dd'), orderSchedule.scheduledType),
    )} ${orderSchedule.startTime}`
    : '-',
});

export const getFormattedOrderChecklists = (response) => Object.values(response).map((orderChecklist) => ({
  taskCategory: {
    id: orderChecklist[0].taskCategoryId,
    title: orderChecklist[0].taskCategoryTitle,
  },
  tasks: orderBy('orderNumber', 'asc')(orderChecklist.map((task, idx) => ({
    orderTaskId: task.id,
    id: task.taskId,
    title: task.taskName,
    completed: task.completed,
    orderNumber: task.orderNumber ? task.orderNumber : idx + 1,
    checklistId: task.checklistId,
    taskCategoryId: task.taskCategoryId,
    connected: task.connected ?? true,
  }))),
}));

export const getFormattedEmployeeActivityJunctions = (activity, order) => {
  const employeeActivityItem = !isEmpty(activity.employeeActivityJunctionItems)
    ? activity.employeeActivityJunctionItems[0] : {};
  const updatedEmployeeActivityItem = !isEmpty(employeeActivityItem)
    ? {
      ...employeeActivityItem,
      isHousework: order.houseworkType !== projectHouseworkTypes.none,
      perUnit: (order?.customer?.vatIncluded
        ? employeeActivityItem.inclVatPerUnit
        : employeeActivityItem.exclVatPerUnit) || employeeActivityItem.inclVatPerUnit,
    }
    : null;

  const getPaidTime = (startTime, endTime) => {
    if (!startTime || !endTime) return;
    return getDifferenceBetweenTime(formatDate(endTime.split('+')[0], formatStrings.defaulTime),
      formatDate(startTime.split('+')[0], formatStrings.defaulTime));
  };

  return {
    ...activity,
    salaryCodeId: activity.codeType === 'SalaryCode' ? activity.codeId : null,
    timeCodeId: activity.codeType === 'TimeCode' ? activity.codeId : null,
    startTime: activity.startTime ? activity.startTime.split('+')[0] : null,
    endTime: activity.endTime ? activity.endTime.split('+')[0] : null,
    paidTime: getPaidTime(activity.startTime, activity.endTime),
    invoiceTime: timeStringToFloat(getPaidTime(activity.startTime, activity.endTime)),
    employeeName: !isEmpty(activity.employee) ? activity.employee.name : null,
    employeeColorCode: !isEmpty(activity.employee) ? activity.employee.colorCode : null,
    totalAmount: activity.quantity && activity.paidAmount
      ? (Number(activity.quantity) * Number(activity.paidAmount)).toFixed(2)
      : null,
    employeeActivityJunctionItem: updatedEmployeeActivityItem,
  };
};

export const formatOrderEmployee = (emp) => {
  const startTime = emp.startTime ? emp.startTime.split('+')[0] : null;
  const endTime = emp.endTime ? emp.endTime.split('+')[0] : null;
  const breakTime = emp.breakTime ?? null;
  const paidTime = getTotalWorkingHoursForProjectOrderEmployees(startTime, endTime, breakTime);

  return {
    ...emp,
    sequenceNum: emp.sequenceNum || emp.employee.sequenceNum,
    startTime: startTime ?? null,
    endTime: endTime ?? null,
    invoiceTime: emp.invoiceTime ? timeStringToFloat(emp.invoiceTime) : null,
    paidTime,
    breakTime,
    overtimes: (emp.overtimes || []),
    employeePunch: isEmpty(emp.employeePunch) ? {} : {
      ...emp.employeePunch,
      punchInTime: emp.employeePunch.punchInTime
        ? formatDate(new Date(emp.employeePunch.punchInTime.split('+')[0]), formatStrings.defaulTime)
        : null,
      punchOutTime: emp.employeePunch.punchOutTime
        ? formatDate(new Date(emp.employeePunch.punchOutTime.split('+')[0]), formatStrings.defaulTime)
        : null,
      totalPunchTime: emp.employeePunch.punchOutTime && emp.employeePunch.punchInTime
        ? getTotalWorkingHoursForProjectOrderEmployees(
          emp.employeePunch.punchInTime.split('+')[0], emp.employeePunch.punchOutTime.split('+')[0],
        )
        : null,
    },
    employeePresenceTimeHistories: (emp.employeePresenceTimeHistories || []).map((history) => ({
      ...history,
      startTime: history.startTime
        ? formatDate(new Date(history.startTime.split('+')[0]), formatStrings.defaulTime) : null,
      endTime: history.endTime
        ? formatDate(new Date(history.endTime.split('+')[0]), formatStrings.defaulTime) : null,
    })),
  };
};

const formatVirtualOrderEmployee = (emp) => {
  const startTime = emp.startTime ? emp.startTime.split('+')[0] : null;
  const endTime = emp.endTime ? emp.endTime.split('+')[0] : null;
  const breakTime = emp.breakTime ?? null;
  const paidTime = getTotalWorkingHoursForProjectOrderEmployees(startTime, endTime, breakTime);

  return {
    ...emp,
    startTime: startTime ?? null,
    endTime: endTime ?? null,
    invoiceTime: emp.invoiceTime ? timeStringToFloat(emp.invoiceTime) : null,
    paidTime,
    breakTime: emp.breakTime ?? null,
    sequenceNum: emp.employee.sequenceNum,
  };
};

export const getFormattedOrderData = (record) => {
  const orderItems = (record.orderItems || [])
    .map((rest) => ({
      ...rest,
      itemDiscount: rest.discountValue,
      isHousework: rest.isHousework,
      integrationDetail: rest.vismaItem,
      account: { number: rest.accountNumber },
    }))
    .map((item) => ({
      ...item,
      ...getItemCost(
        { ...item },
        { ...record, houseworkType: capitalize(record.houseworkType) },
      ),
    }));
  const orderPriceAttributes = getItemsTotalCosts(orderItems, record);
  return {
    ...record,
    customer: {
      ...record.customer,
      customerAddresses: record.customerAddresses,
    },
    orderTags: record.tagsData.map((tag) => ({ ...tag, title: tag.name })),
    orderService: {
      title: record.service ?? record.serviceName,
      colorCode: record.serviceColorCode,
      id: record.serviceId,
      sequenceNum: record.serviceSequenceNum,
    },
    orderItems,
    originalDate: record.date,
    sequenceNum: record.sequenceNum || record.orderSequenceNum,
    orderPriceAttributes,
    notes: record.orderNote?.notes,
    orderEmployees: (record.orderEmployees).map(formatOrderEmployee),
    orderChecklists: getFormattedOrderChecklists(record.orderChecklists || []),
    employeeActivityJunctions: record.employeeActivityJunctions.map(
      (junction) => getFormattedEmployeeActivityJunctions(junction, record),
    ),
    orderNote: record.orderNote,
    scheduledMessage: record.scheduledMessage ? getFormattedScheduledData(record.scheduledMessage) : null,
  };
};

const formatOrderItemsDataAndCalculateCost = (order) => (order.orderItems || [])
  .map(({ unit, ...rest }) => ({
    ...rest,
    itemDiscount: rest.discountValue,
    integrationDetail: rest.vismaItem,
    unit: unit === 'tim' ? 'h' : unit,
  }))
  .map((item) => ({ ...item, ...getItemCost(item, order) }));

const changeDateInEmployeeTime = (originalDateTimeString, newDateString) => {
  const timeFormat = 'HH:mm:ss.SSS';
  const originalDate = parseISO(originalDateTimeString);
  const timePart = formatDate(originalDate, timeFormat);
  return `${newDateString}T${timePart}`;
};

const formatProjectEmployeeForVirtualOrder = (projectEmployee, orderDate) => {
  const startTime = projectEmployee.startTime
    ? changeDateInEmployeeTime(projectEmployee.startTime.split('+')[0], orderDate) : null;
  let endTime;

  if (projectEmployee.startTime && projectEmployee.endTime) {
    if (convertISOStringToTime(startTime) > convertISOStringToTime(projectEmployee.endTime.split('+')[0])) {
      const endTimeDate = formatDate(addDays(orderDate, 1), formatStrings.filtersDate);
      endTime = changeDateInEmployeeTime(projectEmployee.endTime.split('+')[0], endTimeDate);
    } else {
      endTime = changeDateInEmployeeTime(projectEmployee.endTime.split('+')[0], orderDate);
    }
  }

  return {
    ...projectEmployee,
    startTime: startTime ?? null,
    endTime: endTime ?? null,
    invoiceTime: projectEmployee.invoiceTime ? timeStringToFloat(projectEmployee.invoiceTime) : null,
    paidTime: getTotalWorkingHoursForProjectOrderEmployees(
      startTime, endTime, projectEmployee.breakTime,
    ),
    overtimes: (projectEmployee.overtimes || []),
    breakTime: projectEmployee.breakTime ?? null,
    colorCode: projectEmployee.employee.colorCode,
    name: projectEmployee.employee.name,
    employee: {
      ...projectEmployee.employee,
      salaryType: projectEmployee.employee.salaryType === 'hourly' ? 1 : 2,
    },
  };
};

// Used to format copy order data
export const getCopyOrderData = (order) => {
  let orderItems = formatOrderItemsDataAndCalculateCost(order);
  orderItems = orderItems.map((d) => omit(['id', 'projectId', 'orderId'])(d));

  const pickRequiredFields = pick(['customer', 'customerAddress', 'customerId', 'customerMobile',
    'customerName', 'date', 'employeeConfirmationRequired', 'endTime', 'startTime', 'flexTimeType',
    'houseworkType', 'priceType', 'published', 'serviceColorCode', 'serviceColorId',
    'serviceId', 'serviceSequenceNum', 'timeType', 'totalHours', 'orderDocuments',
  ]);

  return {
    ...pickRequiredFields(order),
    smsScheduled: Boolean(order.smsScheduled),
    isRecurring: Boolean(order.orderRecurringRule),
    orderPriceAttributes: getItemsTotalCosts(orderItems, order),
    orderAddresses: (order.orderAddresses || []).map(
      (orderAddress) => omit(['id', 'createdAt', 'updatedAt', 'projectId', 'orderId'])(orderAddress),
    ),
    notes: order.orderNote.notes,
    orderService: {
      title: order.serviceName || order.service.title,
      colorCode: order.serviceColorCode,
      id: order.serviceId,
    },
    serviceName: order.serviceName ?? order.service.title,
    orderItems,
    orderNote: undefined,
    orderEmployees: (order.orderEmployees || []).map((employee) => ({
      ...omit([
        'id',
        'projectId',
        'orderId',
        'overtimes',
        'timeHistoryCount',
        'timeRequestStatus',
        'confirmedAt',
      ])(formatOrderEmployee(employee)),
    })),
    orderTags: (order.tagsData || order.orderTags).map((tag) => ({
      ...omit(['id'])(tag),
      tagId: tag.tagId,
      title: tag.name,
    })),
    orderChecklists: !Array.isArray(order.orderChecklists)
      ? getFormattedOrderChecklists(order.orderChecklists || [])
      : order.orderChecklists,
    employeeActivityJunctions: (order.employeeActivityJunctions || []).map((junction) => ({
      ...omit(['id'])(junction),
      employeeColorCode: junction.employee.colorCode,
      employeeName: junction.employee.name,
      employeeActivityJunctionItem: omit(['id'])(junction.employeeActivityJunctionItems[0]),
    })),
  };
};

export const getCopyVirtualOrderData = (order) => {
  let orderItems = formatOrderItemsDataAndCalculateCost(order);
  orderItems = orderItems.map((d) => omit(['id', 'projectId', 'orderId'])(d));

  const pickRequiredFields = pick(['customer', 'customerAddress', 'customerId', 'customerMobile',
    'customerName', 'date', 'employeeConfirmationRequired', 'endTime', 'startTime', 'flexTimeType',
    'houseworkType', 'priceType', 'published', 'serviceColorCode', 'serviceColorId',
    'serviceId', 'serviceSequenceNum', 'timeType', 'totalHours', 'orderDocuments',
  ]);

  return {
    ...pickRequiredFields(order),
    smsScheduled: Boolean(order.smsScheduled),
    isRecurring: Boolean(order.orderRecurringRule),
    orderPriceAttributes: getItemsTotalCosts(orderItems, order),
    orderAddresses: (order.orderAddresses || []).map(
      (orderAddress) => omit(['id', 'createdAt', 'updatedAt', 'projectId', 'orderId'])(orderAddress),
    ),
    notes: order.orderNote ? order.orderNote.notes : null,
    orderService: {
      title: order.serviceName || order.service.title,
      colorCode: order.serviceColorCode,
      id: order.serviceId,
    },
    serviceName: order.serviceName ?? order.service.title,
    orderItems,
    orderNote: undefined,
    orderEmployees: (order.orderEmployees || []).map((employee) => ({
      ...omit([
        'id',
        'projectId',
        'orderId',
        'overtimes',
        'timeHistoryCount',
        'timeRequestStatus',
        'confirmedAt',
      ])(formatVirtualOrderEmployee(employee)),
    })),
    orderTags: (order.tagsData || order.orderTags).map((tag) => ({
      ...omit(['id'])(tag),
      tagId: tag.tagId,
      title: tag.name,
    })),
    orderChecklists: !Array.isArray(order.orderChecklists)
      ? getFormattedOrderChecklists(order.orderChecklists || [])
      : order.orderChecklists,
    employeeActivityJunctions: (order.employeeActivityJunctions || []).map((junction) => ({
      ...omit(['id'])(junction),
      employeeColorCode: junction.employee.colorCode,
      employeeName: junction.employee.name,
      employeeActivityJunctionItem: omit(['id'])(junction.employeeActivityJunctionItems[0]),
    })),
  };
};

export const getFormattedOrderFilterParams = (filters = {}) => ({
  page: filters.page || 1,
  per_page: filters.perPage,
  customer_id: getFilterCustomerId(filters),
  service_ids: getFilterServiceId(filters),
  employee_id: getFilterEmployeeId(filters),
  status: filters.orderStatus === 'view_all' ? undefined : filters.orderStatus,
  start_date: filters.startDate,
  end_date: filters.endDate,
  tag_id: filters.tag ? filters.tag.id : undefined,
  project_id: filters.projectId,
  order_id: filters.orderId !== '' ? filters.orderId : undefined,
  order_by: snakify(filters.orderBy),
  published: filters.publishStatus === 'view_all' ? undefined : filters.publishStatus,
  order: filters.order,
  invoice_type: filters.invoiceStatus,
  project_orders: filters.projectOrders === 'view_all' ? undefined : filters.projectOrders,
  customer_area: filters.customerArea?.title,
  skill_id: filters.skill?.id,
});

export const getFormattedSmsOrEmailRequestBody = (data) => ({
  email: {
    email: Boolean(data.emailFieldsToInclude),
    ...(data.emailFieldsToInclude && snakify({ fieldsToInclude: data.emailFieldsToInclude })),
  },
  sms: !isEmpty(data.sendSmsData) ? {
    send_sms: data.sendSmsData.sendSmsNow,
    sms_on_complete: data.sendSmsData.smsOnComplete,
    sms_text: data.sendSmsData ? getSmsDescription(data.sendSmsData.customSms, smsDataTypeAPI) : null,
    scheduled_message: data.sendSmsData.scheduled ? {
      destroy: data.sendSmsData.destroy,
      message_text: getSmsDescription(data.sendSmsData.customSms, smsDataTypeAPI),
      scheduled_type: data.sendSmsData.scheduledType,
      sms_template_id: data.sendSmsData.smsTemplateId,
    } : undefined,
  } : null,
});

export const getFormattedSmsBodyForOrder = (data) => ({
  order: {
    send_sms: data.sendSmsNow,
    sms_text: getSmsDescription(data.customSms, smsDataTypeAPI),
    sms_on_complete: data.smsOnComplete,
    scheduled_message: data.scheduled ? {
      destroy: data.destroy,
      message_text: getSmsDescription(data.customSms, smsDataTypeAPI),
      scheduled_type: data.scheduledType,
      sender_type: data.role,
      sms_template_id: data.smsTemplateId,
    } : undefined,
  },
});

export const getFormattedVirtualOrderData = (record) => {
  const orderItems = (record.projectItems || [])
    .map((rest) => ({ ...rest, integrationDetail: rest.vismaItem, itemDiscount: rest.discountValue }))
    .map((item) => ({
      ...item,
      ...getItemCost(
        { ...item },
        { ...record, houseworkType: capitalize(record.houseworkType) },
      ),
    }));
  const orderPriceAttributes = getItemsTotalCosts(orderItems, record);

  return {
    ...record,
    customer: {
      ...record.customer,
      customerAddresses: record.customer.customerAddresses.filter((address) => address.showToBookings),
    },
    orderTags: (record.projectTags || []).map((tag) => ({ ...tag, title: tag.name })),
    originalDate: record.date,
    projectId: record.id,
    id: `${record.id}-${formatDate(record.date, formatStrings.customDate)}`,
    projectSequenceNum: record.sequenceNum,
    sequenceNum: `${record.sequenceNum}-${formatDate(record.date, formatStrings.customDate)}`,
    virtual: true,
    orderService: {
      ...record.service,
      colorCode: record.service.color.code,
      colorCodeId: record.service.color.id,
    },
    orderItems,
    orderPriceAttributes,
    notes: record.projectNote?.notes,
    orderEmployees: (record.projectEmployees || []).map(
      (projectEmployee) => formatProjectEmployeeForVirtualOrder(projectEmployee, record.date),
    ),
    orderAddresses: record.projectAddresses,
    orderChecklists: getFormattedOrderChecklists(record.projectChecklists || []),
    employeeActivityJunctions: record.employeeActivityJunctions.map(
      (junction) => getFormattedEmployeeActivityJunctions(junction, record),
    ),
    orderNote: record.projectNote,
    project: {
      smsOnComplete: record.smsOnComplete,
      scheduledMessage: record.scheduledMessage ? getFormattedScheduledData(record.scheduledMessage) : null,
    },
    scheduledMessage: undefined,
    sms_on_complete: undefined,
    status: orderStatus.active,
  };
};

export const getFormattedFieldsToUpdate = (fieldsToUpdate) => ({
  order_service: fieldsToUpdate.orderService,
  order_date_time_fields: fieldsToUpdate.orderDateTimeFields,
  update_all: fieldsToUpdate.updateAll,
  order_tags: fieldsToUpdate.orderTags,
  notes: fieldsToUpdate.notes,
  price: fieldsToUpdate.price,
  order_employees: fieldsToUpdate.orderEmployees,
  order_addresses: fieldsToUpdate.orderAddresses,
  order_checklists: fieldsToUpdate.orderChecklists,
  published: fieldsToUpdate.published,
  invoice_hours: fieldsToUpdate.invoiceHours,
  employee_confirmation_required: fieldsToUpdate.employeeConfirmationRequired,
  employee_activity: fieldsToUpdate.employeeActivity,
});
