import { createApi } from '@reduxjs/toolkit/query/react';
import snakify from 'utils/snakify';
import { pick } from 'utils/lodash';
import axiosBaseQuery from 'appRedux/api/axiosBaseQuery';

export const superAdminCustomerOverviewApi = createApi({
  reducerPath: 'superAdminCustomerOverviewApi',
  baseQuery: axiosBaseQuery(),
  selectFromResult: (state) => state.superUser.superAdminCustomerOverviewApi,
  endpoints: (builder) => ({
    getSuperAdminCustomerStats: builder.query({
      query: (filters) => ({
        url: '/api/v3/super_admin/company_insights/stats',
        method: 'GET',
        params: snakify({
          ...pick(['startDate', 'endDate'])(filters),
        }),
      }),
    }),
    getSuperAdminPaidCustomers: builder.query({
      query: (filters) => ({
        url: '/api/v3/super_admin/company_insights/companies',
        method: 'GET',
        params: snakify({
          ...pick(['startDate', 'endDate', 'page', 'perPage', 'query'])(filters),
        }),
      }),
    }),
    getSuperAdminCompanies: builder.query({
      query: (filters) => ({
        url: '/api/v1/super_admin/companies',
        method: 'GET',
        params: snakify({
          ...pick(['page', 'perPage', 'query'])(filters),
          ...(filters.listCompaniesType !== 'view_all' && { [snakify(filters.listCompaniesType)]: true }),
        }),
      }),
    }),
    getSuperAdminCustomersSaasMatrics: builder.query({
      query: (filters) => ({
        url: '/api/v3/super_admin/company_insights/sass_matrics',
        method: 'GET',
        params: snakify({
          ...pick(['startDate', 'endDate'])(filters),
        }),
      }),
    }),
    getSuperAdminCustomerDetails: builder.query({
      query: (companyId) => ({
        url: `/api/v3/super_admin/company_insights/${companyId}/company_details`,
        method: 'GET',
      }),
    }),
    getSuperAdminCustomerLatestInvoices: builder.query({
      query: (filters) => ({
        url: `/api/v3/super_admin/company_insights/${filters.companyId}/latest_invoices`,
        method: 'GET',
        params: snakify({
          ...pick(['page', 'perPage'])(filters),
        }),
      }),
    }),
    setSuperAdminAutoReminderInvoiceStatus: builder.mutation({
      query: (companyId) => ({
        url: `/api/v3/super_admin/company_insights/${companyId}/set_invoice_reminder_status`,
        method: 'PUT',
      }),
    }),
    sendSuperAdminManualInvoiceReminder: builder.mutation({
      query: (body) => ({
        url: `/api/v3/super_admin/company_insights/${body.companyId}/send_invoice_reminder`,
        method: 'POST',
        params: snakify({
          ...pick(['invoiceId'])(body),
        }),
      }),
    }),
    sendSuperAdminInvoiceToCustomer: builder.mutation({
      query: (body) => ({
        url: `api/v3/super_admin/company_insights/${body.companyId}/send_invoice`,
        method: 'POST',
        params: snakify({
          ...pick(['invoiceId'])(body),
        }),
      }),
    }),
    createSuperAdminInvoiceForCustomer: builder.mutation({
      query: (body) => ({
        url: `api/v3/super_admin/company_insights/${body.companyId}/create_invoice`,
        method: 'POST',
        params: snakify({
          ...pick(['invoiceId'])(body),
        }),
      }),
    }),
    createSuperAdminCompanyNote: builder.mutation({
      query: (body) => ({
        url: 'api/v3/super_admin/company_notes',
        method: 'POST',
        params: snakify(body),
      }),
    }),
    updateSuperAdminCompanyNote: builder.mutation({
      query: (body) => ({
        url: `api/v3/super_admin/company_notes/${body.id}`,
        method: 'PUT',
        params: snakify({
          ...pick(['companyId', 'description'])(body),
        }),
      }),
    }),
    deleteSuperAdminCompanyNote: builder.mutation({
      query: (body) => ({
        url: `/api/v3/super_admin/company_notes/${body.id}`,
        method: 'DELETE',
        params: snakify({
          ...pick(['companyId'])(body),
        }),
      }),
    }),
    getSuperAdminCompanyNote: builder.query({
      query: (body) => ({
        url: `/api/v3/super_admin/company_notes/${body.id}`,
        method: 'GET',
        params: snakify({
          ...pick(['companyId'])(body),
        }),
      }),
    }),
    getSuperAdminCompanyNotes: builder.query({
      query: (filters) => ({
        url: '/api/v3/super_admin/company_notes',
        method: 'GET',
        params: snakify(filters),
      }),
    }),
    getSuperAdminCompanyInvoiceEmailActivity: builder.query({
      query: (data) => ({
        url: `api/v3/super_admin/company_insights/${data.companyId}/invoice_email_activity`,
        method: 'GET',
        params: snakify({
          ...pick(['page', 'perPage'])(data),
        }),
      }),
    }),
    createSuperAdminFortnoxCustomer: builder.mutation({
      query: (companyId) => ({
        url: `api/v3/super_admin/company/${companyId}/create_fortnox_customer`,
        method: 'POST',
      }),
    }),
    setSuperAdminCompanyTrialEndDate: builder.mutation({
      query: (companyData) => ({
        url: '/api/v1/super_admin/update_trial_end_date',
        method: 'PUT',
        data: snakify(companyData),
      }),
    }),
    activateSuperAdminCompany: builder.query({
      query: (companyId) => ({ url: `/api/v1/super_admin/company_activate/${companyId}`, method: 'GET' }),
    }),
    deactivateSuperAdminCompany: builder.query({
      query: ({ companyId, ...body }) => ({
        url: `/api/v1/super_admin/company_deactivate/${companyId}`,
        method: 'GET',
        params: snakify(body),
      }),
    }),
    toggleSuperAdminCompanyToTestCompany: builder.mutation({
      query: (companyId) => ({
        url: `/api/v1/super_admin/set_sandbox_mode/${companyId}`,
        method: 'PUT',
      }),
    }),
    getCompanyFortnoxCustomer: builder.query({
      query: ({ companyId }) => ({
        url: `/api/v3/super_admin/company/${companyId}/fetch_fortnox_customer`,
        method: 'GET',
      }),
    }),
    getSuperAdminCompanyActiveSubscription: builder.query({
      query: (companyId) => ({ url: `/api/v3/super_admin/company/${companyId}/active_subscription`, method: 'GET' }),
    }),
    setSuperAdminCompanyActiveSubscription: builder.mutation({
      query: ({ companyId, ...body }) => ({
        url: `/api/v3/super_admin/company/${companyId}/update_active_subscription`,
        method: 'PUT',
        data: snakify(body),
      }),
    }),
    setCompanyFortnoxCustomer: builder.mutation({
      query: (companyData) => ({
        url: `/api/v3/super_admin/company/${companyData.fortnoxCustomer.companyId}/update_fortnox_customer`,
        method: 'PUT',
        data: snakify(companyData),
      }),
    }),
    createSuperAdminCompanyInvoiceConfig: builder.mutation({
      query: ({ companyId, ...restData }) => ({
        url: '/api/v3/super_admin/company_invoicing_configuration',
        method: 'POST',
        data: snakify({
          companyId,
          companyInvoicingConfiguration: restData,
        }),
      }),
    }),
    updateSuperAdminCompanyInvoiceConfig: builder.mutation({
      query: ({ companyId, ...restData }) => ({
        url: '/api/v3/super_admin/company_invoicing_configuration',
        method: 'PUT',
        data: snakify({
          companyId,
          companyInvoicingConfiguration: restData,
        }),
      }),
    }),
    deleteSuperAdminCompanyInvoiceConfig: builder.mutation({
      query: (companyId) => ({
        url: '/api/v3/super_admin/company_invoicing_configuration',
        method: 'DELETE',
        params: snakify({ companyId }),
      }),
    }),
    getSuperAdminCompanyInvoiceConfig: builder.query({
      query: (companyId) => ({
        url: '/api/v3/super_admin/company_invoicing_configuration',
        method: 'GET',
        params: snakify({ companyId }),
      }),
    }),
  }),
});

export const {
  useGetSuperAdminCustomerStatsQuery,
  useGetSuperAdminPaidCustomersQuery,
  useLazyGetSuperAdminPaidCustomersQuery,
  useGetSuperAdminCompaniesQuery,
  useGetSuperAdminCustomerDetailsQuery,
  useGetSuperAdminCustomerLatestInvoicesQuery,
  useSetSuperAdminAutoReminderInvoiceStatusMutation,
  useSendSuperAdminManualInvoiceReminderMutation,
  useSendSuperAdminInvoiceToCustomerMutation,
  useCreateSuperAdminInvoiceForCustomerMutation,
  useGetSuperAdminCustomersSaasMatricsQuery,
  useCreateSuperAdminCompanyNoteMutation,
  useUpdateSuperAdminCompanyNoteMutation,
  useDeleteSuperAdminCompanyNoteMutation,
  useGetSuperAdminCompanyNotesQuery,
  useGetSuperAdminCompanyNoteQuery,
  useGetSuperAdminCompanyInvoiceEmailActivityQuery,
  useCreateSuperAdminFortnoxCustomerMutation,
  useSetSuperAdminCompanyTrialEndDateMutation,
  useLazyActivateSuperAdminCompanyQuery,
  useLazyDeactivateSuperAdminCompanyQuery,
  useToggleSuperAdminCompanyToTestCompanyMutation,
  useGetCompanyFortnoxCustomerQuery,
  useGetSuperAdminCompanyActiveSubscriptionQuery,
  useSetSuperAdminCompanyActiveSubscriptionMutation,
  useSetCompanyFortnoxCustomerMutation,
  useCreateSuperAdminCompanyInvoiceConfigMutation,
  useUpdateSuperAdminCompanyInvoiceConfigMutation,
  useDeleteSuperAdminCompanyInvoiceConfigMutation,
  useGetSuperAdminCompanyInvoiceConfigQuery,
} = superAdminCustomerOverviewApi;
