import { DepartureBoardIcon, ReceiptLongIcon } from 'components/library/icons';
import { fm } from 'utils/string';

export const salaryPaidTypes = {
  time: 'time_code',
  expense: 'expense',
  // unpaidTime: 'unpaid',
};

export const salaryPaidTypeOptions = [
  { label: fm('salary_paid_time'), value: salaryPaidTypes.time, icon: DepartureBoardIcon },
  { label: fm('salary_mileage_expense'), value: salaryPaidTypes.expense, icon: ReceiptLongIcon },
  // { label: fm('salary_unpaid_time'), value: salaryPaidTypes.unpaidTime },
];

export const employeeActivitySalaryTypes = {
  expense: 'EmployeeActivityExpense',
  timeCode: 'EmployeeActivityTimeCode',
};

export const activityTabsTypes = {
  activityDetails: 0,
  price: 1,
};
