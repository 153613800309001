import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from 'appRedux/api/axiosBaseQuery';
import { get } from 'utils/lodash';

export const customerApi = createApi({
  reducerPath: 'customer',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getCustomerExcelImportStatus: builder.query({
      query: () => ({ url: '/api/v3/customers/get_customer_import_status', method: 'GET' }),
    }),
    fetchBookkeepingCustomer: builder.query({
      query: (fortnoxId) => ({
        url: '/api/v1/customers/fetch_from_remote',
        method: 'GET',
        params: { remote_customer_num: encodeURIComponent(fortnoxId) },
      }),
      transformResponse: get('data.customer'),
    }),
  }),
});

export const {
  useGetCustomerExcelImportStatusQuery,
  useFetchBookkeepingCustomerQuery,
} = customerApi;
