import {
  SET_SUPERVISOR_SUGGESTIONS,
  SET_VISMA_ITEM_SUGGESTIONS,
  SET_CUSTOMER_SUGGESTIONS,
  SET_EMPLOYEE_SUGGESTIONS,
  SET_USER_GROUP_SUGGESTIONS,
  SET_SERVICE_SUGGESTIONS,
  SET_ITEM_SUGGESTIONS,
  SET_TASK_SUGGESTIONS,
  SET_KEY_SUGGESTIONS,
  SET_TASK_CATEGORY_SUGGESTIONS,
  SET_PREDEFINED_CHECKLIST_SUGGESTIONS,
  SET_CHECKLIST_COLLECTION_SUGGESTIONS,
  SET_VISMA_ITEM_NUMBER_SUGGESTIONS,
  SET_AUTOCOMPLETE_FIELDS,
  SET_DEPARTMENT_SUGGESTIONS,
  SET_AREA_SUGGESTIONS,
  SET_CUSTOMER_AREA_SUGGESTIONS,
  SET_CUSTOMER_ZIP_CODE_SUGGESTIONS,
  SET_SKILLS_SUGGESTIONS,
  SET_FORTNOX_ITEM_SUGGESTIONS,
  SET_SUPER_ADMIN_USER_SUGGESTIONS,
  SET_EMPLOYEE_ACTIVITY_SUGGESTIONS,
  SET_EMPLOYEE_VIEW_ACTIVITY_SUGGESTIONS,
} from '../actions';

const initialState = {
  supervisor: { records: [] },
  vismaItems: { records: [] },
  fortnoxItem: { records: [] },
  customer: {
    records: [],
    showAdditionalInfo: JSON.parse(localStorage.getItem('_showCustomerSuggestionsAdditionInfo') || true),
  },
  employee: { records: [] },
  userGroup: { records: [] },
  service: { records: [] },
  item: { records: [] },
  task: { records: [] },
  taskCategory: { records: [] },
  bookingChecklists: { records: [] },
  helpModule: { records: [] },
  checklistCollection: { records: [] },
  key: { records: [] },
  vismaItemNumber: { records: [] },
  department: { records: [] },
  area: { records: [] },
  customerArea: { records: [] },
  customerZipcode: { records: [] },
  skills: { records: [] },
  superAdminUser: { records: [] },
  employeeActivity: { records: [] },
  employeeViewActivity: { records: [] },
};

function autocomplete (state = initialState, action) {
  switch (action.type) {
    case SET_AUTOCOMPLETE_FIELDS:
      return {
        ...state,
        ...action.payload,
      };
    case SET_SUPERVISOR_SUGGESTIONS:
      return {
        ...state,
        supervisor: {
          ...state.supervisor,
          ...action.payload,
        },
      };
    case SET_VISMA_ITEM_SUGGESTIONS:
      return {
        ...state,
        vismaItem: {
          ...state.vismaItem,
          ...action.payload,
        },
      };
    case SET_FORTNOX_ITEM_SUGGESTIONS:
      return {
        ...state,
        fortnoxItem: {
          ...state.fortnoxItem,
          ...action.payload,
        },
      };
    case SET_CUSTOMER_SUGGESTIONS:
      return {
        ...state,
        customer: {
          ...state.customer,
          ...action.payload,
        },
      };
    case SET_EMPLOYEE_SUGGESTIONS:
      return {
        ...state,
        employee: {
          ...state.employee,
          ...action.payload,
        },
      };
    case SET_SERVICE_SUGGESTIONS:
      return {
        ...state,
        service: {
          ...state.service,
          ...action.payload,
        },
      };
    case SET_ITEM_SUGGESTIONS:
      return {
        ...state,
        item: {
          ...state.item,
          ...action.payload,
        },
      };
    case SET_TASK_SUGGESTIONS:
      return {
        ...state,
        task: {
          ...state.task,
          ...action.payload,
        },
      };
    case SET_USER_GROUP_SUGGESTIONS:
      return {
        ...state,
        userGroup: {
          ...state.userGroup,
          ...action.payload,
        },
      };
    case SET_TASK_CATEGORY_SUGGESTIONS:
      return {
        ...state,
        taskCategory: {
          ...state.taskCategory,
          ...action.payload,
        },
      };
    case SET_PREDEFINED_CHECKLIST_SUGGESTIONS:
      return {
        ...state,
        bookingChecklists: {
          ...state.bookingChecklists,
          ...action.payload,
        },
      };
    case SET_CHECKLIST_COLLECTION_SUGGESTIONS:
      return {
        ...state,
        checklistCollection: {
          ...state.checklistCollection,
          ...action.payload,
        },
      };
    case SET_KEY_SUGGESTIONS:
      return {
        ...state,
        key: {
          ...state.key,
          ...action.payload,
        },
      };
    case SET_VISMA_ITEM_NUMBER_SUGGESTIONS:
      return {
        ...state,
        vismaItemNumber: {
          ...state.vismaItemNumber,
          ...action.payload,
        },
      };
    case SET_DEPARTMENT_SUGGESTIONS:
      return {
        ...state,
        department: {
          ...state.department,
          ...action.payload,
        },
      };
    case SET_AREA_SUGGESTIONS:
      return {
        ...state,
        area: {
          ...state.area,
          ...action.payload,
        },
      };
    case SET_CUSTOMER_AREA_SUGGESTIONS:
      return {
        ...state,
        customerArea: {
          ...state.customerArea,
          ...action.payload,
        },
      };
    case SET_CUSTOMER_ZIP_CODE_SUGGESTIONS:
      return {
        ...state,
        customerZipcode: {
          ...state.customerZipcode,
          ...action.payload,
        },
      };
    case SET_SKILLS_SUGGESTIONS:
      return {
        ...state,
        skills: {
          ...state.skills,
          ...action.payload,
        },
      };
    case SET_SUPER_ADMIN_USER_SUGGESTIONS:
      return {
        ...state,
        superAdminUser: {
          ...state.superAdminUser,
          ...action.payload,
        },
      };
    case SET_EMPLOYEE_ACTIVITY_SUGGESTIONS:
      return {
        ...state,
        employeeActivity: {
          ...state.employeeActivity,
          ...action.payload,
        },
      };
    case SET_EMPLOYEE_VIEW_ACTIVITY_SUGGESTIONS:
      return {
        ...state,
        employeeViewActivity: {
          ...state.employeeViewActivity,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}

export default autocomplete;
