import { keyBy } from 'utils/lodash';
import { smsTemplateType, smsDataType } from 'utils/enum';
import { getSmsDescription } from 'appRedux/utility';

export const getFormattedTemplate = (template) => {
  if (!template) return null;
  return {
    ...template,
    templateDescription: getSmsDescription(template.templateDescription, smsDataType),
  };
};

export const getFormattedSmsTemplate = (records) => {
  const templatesByTypeMap = keyBy('templateType')(records);
  const activeTemplates = records.filter((d) => d.templateType === smsTemplateType.active).map(getFormattedTemplate);
  const templateData = {
    activeTemplates,
    records: {
      [smsTemplateType.proposalCreate]: getFormattedTemplate(templatesByTypeMap[smsTemplateType.proposalCreate]),
      [smsTemplateType.proposalReminder]: getFormattedTemplate(templatesByTypeMap[smsTemplateType.proposalReminder]),
      [smsTemplateType.completed]: getFormattedTemplate(templatesByTypeMap[smsTemplateType.completed]),
      [smsTemplateType.cancelled]: getFormattedTemplate(templatesByTypeMap[smsTemplateType.cancelled]),
      [smsTemplateType.active]: activeTemplates.find((record) => record.default)
        || getFormattedTemplate(templatesByTypeMap[smsTemplateType.active]),
    },
  };

  return templateData;
};
