export const organizationSettingTypes = {
  unkown: 'CS_101',
  storage: 'CS_102',
  bookingInvoiceTime: 'CS_103',
  overtime: 'CS_104',
  bookingCustomerUpdate: 'CS_105',
  schedule: 'CS_106',
  punchTime: 'CS_107',
  proposalBankIdSignature: 'CS_108',
  salaryTimeCodes: 'CS_109',
};
