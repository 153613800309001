import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from 'appRedux/api/axiosBaseQuery';
import snakify from 'utils/snakify';

export const projectApi = createApi({
  reducerPath: 'projectApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['ProjectDeletedOrders'],
  endpoints: (builder) => ({
    getDeletedProjectOrders: builder.query({
      providesTags: ['ProjectDeletedOrders'],
      query: ({ projectId, ...filters }) => ({
        url: `/api/v3/projects/${projectId}/project_ex_dates`,
        method: 'GET',
        params: snakify(filters),
      }),
      transformResponse: (response) => response.data || {},
    }),
  }),
});

export const { useGetDeletedProjectOrdersQuery } = projectApi;
