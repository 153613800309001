import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TableSortLabel from '@mui/material/TableSortLabel';
import { fm } from 'utils/string';
import { TableCell, TableRow } from './styledComponents';
import Row from './Row';
import TablePagination from './TablePagination';
import { OrderOptions } from './OrderOptions';

export default function TableNext(props) {
  const {
    data,
    columns,
    subtableColumns,
    onExpandRow,
    subtableDataAccessor,
    getSubtablePagination,
    showSubtablePagination,
    onChangeSubtablePagination,
    showPagination,
    onSubtableRowClick,
    pagination,
    paperSx,
    TableFooter,
    onRowClick,
    order,
    orderBy,
    onChangeOrder,
    onChangeOrderValue,
  } = props;

  const createSortHandler = (headCell) => (event) => {
    onChangeOrder(headCell.id, event, { resetOrderValue: !headCell.orderOptions });
  };

  const createChangeOrderValueHandler = (headCell) => (option) => {
    onChangeOrderValue(option, headCell);
  };

  const dataKeys = useMemo(() => data.map((_, idx) => idx * Math.random()), [data]);

  return (
    <>
      <Paper sx={paperSx}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                {subtableColumns && <TableCell />}
                {columns.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.align}
                    minWidth={headCell.minWidth}
                    padding={headCell.cellPading}
                    className="flex align-center"
                  >
                    {headCell.orderable ? (
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell)}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    ) : (
                      headCell.label
                    )}
                    {headCell.orderOptions && (
                      <OrderOptions
                        options={headCell.orderOptions}
                        onChangeOrderValue={createChangeOrderValueHandler(headCell)}
                      />
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length ? data.map((row, idx) => (
                <Row
                  key={dataKeys[idx]}
                  row={row}
                  columns={columns}
                  subtableColumns={subtableColumns}
                  subtableDataAccessor={subtableDataAccessor}
                  onExpandRow={onExpandRow}
                  onSubtableRowClick={onSubtableRowClick}
                  getSubtablePagination={getSubtablePagination}
                  showSubtablePagination={showSubtablePagination}
                  onChangeSubtablePagination={onChangeSubtablePagination}
                  onRowClick={onRowClick}
                />
              )) : (
                <TableRow>
                  <TableCell colSpan={12}>
                    <Box textAlign="center">
                      <Typography variant="caption">
                        {fm('no_records_found')}
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {showPagination && (
          <Box display="flex" justifyContent="end">
            <TablePagination {...pagination} />
          </Box>
        )}
      </Paper>
      {TableFooter && <TableFooter />}
    </>
  );
}

TableNext.defaultProps = {
  paperSx: { width: '100%', overflow: 'hidden' },
};

TableNext.propTypes = {
  onChangeOrderValue: PropTypes.func,
  onExpandRow: PropTypes.func,
  onRowClick: PropTypes.func,
  subtableDataAccessor: PropTypes.func,
  onSubtableRowClick: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  subtableColumns: PropTypes.arrayOf(PropTypes.shape({})),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  paperSx: PropTypes.shape({}),
  pagination: PropTypes.shape({}),
  showPagination: PropTypes.bool,
  TableFooter: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  onChangeOrder: PropTypes.func,
  showSubtablePagination: PropTypes.bool,
  getSubtablePagination: PropTypes.func,
  onChangeSubtablePagination: PropTypes.func,
};
