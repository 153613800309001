import camelize from 'camelize';
import { flow, groupBy, get, map, values, orderBy } from 'utils/lodash';
import { parseJson } from 'appRedux/selectors/utility';
import getItemsTotalCosts, { getItemCost } from 'components/Booking/BookingModal/PriceTab/getTotalCosts';
import { capitalize } from 'utils/string';
import { houseworkTypes } from 'components/Booking/BookingModal/utility';

export const getFormatedTaskSearchData = (records = []) => (
  records.map((record) => ({ title: record.name, id: record.id, connected: record.connected ?? true }))
);

export const getFormattedPredefinedChecklistData = (records = []) => (
  records.map((record) => ({
    id: record.task_category_id,
    title: record.task_category_name,
    tasksCount: record.tasks_count,
    orderNumber: record.order_number,
  }))
);

export const getFormattedChecklistObjectsData = (data, searchObjectType) => data.map((record) => ({
  ...camelize(record),
  searchObjectType,
  ...(record.tasksData && { tasksData: orderBy('orderNumber', 'asc')(parseJson(record.tasksData)) }),
  ...(record.checklistsData && {
    checklistsData: flow(
      parseJson,
      groupBy('taskCategoryId'),
      values,
      map((checklist) => ({
        taskCategory: { id: checklist[0].taskCategoryId, title: checklist[0].taskCategoryTitle },
        tasks: orderBy('orderNumber', 'asc')(checklist.map((task) => ({
          title: task.taskName,
          id: task.taskId,
          connected: task.connected ?? true,
          orderNumber: task.orderNumber,
        }))),
      })),
    )(record.checklistsData),
  }),
}));

export const getFormattedCustomerSuggestionsData = (records, company) => {
  const bookkeepingIntegrationFieldName = {
    Fortnox: 'fortnoxId',
    Visma: 'vismaNumber',
  }[get('integration.name')(company)];

  return records.map((record) => ({
    ...record,
    bookkeepingIntegrationFieldName,
  }));
};

export const getFormattedItemData = (records) => records.map((record) => {
  const integrationDetails = parseJson(record.integrationDetail);

  return ({
    ...record,
    integrationDetail: integrationDetails && {
      ...integrationDetails,
      houseworkType: houseworkTypes[integrationDetails.houseworkType],
      isHouseWork: integrationDetails.isHousework,
    },
  });
});

export const getFormattedProposalTemplateRecords = (records) => records.map((record) => {
  const { proposalTemplate, document } = record;
  const proposalItems = (proposalTemplate.proposalTemplateItems || [])
    .map((rest) => ({
      ...rest,
      itemDiscount: rest.discountValue,
      isHouseWork: rest.isHousework,
    }))
    .map((item) => ({
      ...item,
      ...getItemCost(
        { ...item, isHouseWork: item.isHousework },
        { ...proposalTemplate, houseworkType: capitalize(proposalTemplate.houseworkType) },
      ),
    }));
  const proposalPriceAttributes = getItemsTotalCosts(proposalItems, proposalTemplate);
  return {
    ...proposalTemplate,
    proposalService: {
      ...proposalTemplate.service,
      colorCode: proposalTemplate.service.color.code,
      colorCodeId: proposalTemplate.service.color.id,
    },
    proposalTags: proposalTemplate.proposalTemplateTags.map((tag) => ({
      ...tag,
      tagId: tag.id,
      title: tag.name,
    })),
    proposalItems,
    proposalPriceAttributes,
    document,
  };
});
