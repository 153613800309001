import { createAsyncThunk } from '@reduxjs/toolkit';
import { setSnackbarSuccess, setSnackbarError } from 'appRedux/actions';
import { requestProposal } from './thunk';
import { getFormattedProposalReminderRequestBody } from './selector';

export const requestCreateProposalReminder = createAsyncThunk(
  'proposal/createProposalReminder',
  async (data, { extra, rejectWithValue, dispatch, getState }) => {
    const { profile } = getState();
    try {
      const body = getFormattedProposalReminderRequestBody(data, profile.company);
      const response = await extra.axios.post('api/v3/proposal_reminders', body);
      dispatch(setSnackbarSuccess('snackbar_proposal_reminder_created'));
      dispatch(requestProposal(data.proposalId));
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_proposal_reminder_create_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestUpdateProposalReminder = createAsyncThunk(
  'proposal/updateProposalReminder',
  async (data, { extra, rejectWithValue, dispatch, getState }) => {
    const { profile } = getState();
    try {
      const body = getFormattedProposalReminderRequestBody(data, profile.company);
      const response = await extra.axios.put(`api/v3/proposal_reminders/${data.id}`, body);
      dispatch(setSnackbarSuccess('snackbar_proposal_reminder_updated'));
      dispatch(requestProposal(data.proposalId));
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_proposal_reminder_update_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestDeleteProposalReminder = createAsyncThunk(
  'proposal/deleteProposalReminder',
  async (data, { extra, rejectWithValue, dispatch }) => {
    try {
      const response = await extra.axios.delete(`api/v3/proposal_reminders/${data.id}`);
      dispatch(setSnackbarSuccess('snackbar_proposal_reminder_deleted'));
      dispatch(requestProposal(data.proposalId));
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_proposal_reminder_delete_error'));
      return rejectWithValue(err);
    }
  },
);
