import { get } from 'utils/lodash';
import { alert } from 'components/library/Alert';
import { fm } from 'utils/string';
import { getDifferenceBetweenLocalStrings, getDifferenceBetweenTime } from 'utils/dateTime';

export const getFilterCustomerId = get('customer.id');
export const getFilterEmployeeId = get('employee.id');
export const getFilterServiceId = get('service.id');
export const getFilterTagId = get('tag.id');
export const getFilterSupervisorId = get('supervisor.id');
export const getFilterCompanyId = get('company.id');
export const getFilterUserId = get('user.id');
export const getFilterTag = get('tag.title');

export const getFormattedPaginationFields = (response) => {
  const pages = Math.ceil(response.totalRecords / Number(response.perPage)) || 1;
  return {
    pages,
    page: Number(response.page) || 1,
    ...(response.page > pages && { page: pages }),
    perPage: Number(response.perPage) || 10,
    totalRecords: response.totalRecords || 0,
  };
};

export const getFormattedListData = (response) => ({
  ...getFormattedPaginationFields(response),
  records: response.records || [],
});

export const handleFortnoxError = (err) => {
  // error code 3003 when fortnox token expired
  if (get('response.data.errors[0].details.code')(err) !== '3003') return;
  alert(fm(get('response.data.errors[0].details.code')(err), { title: 'Fortnox' }));
};

export const handleVismaError = (err) => {
  // error code 16000 when visma token expired
  if (get('response.data.errors[0].details.error.code')(err) === '16000') {
    return alert(fm(get('response.data.errors[0].details.error.code')(err)), { title: 'Visma' });
  }
  if (get('response.data.errors[0].details.error_key.code')(err) === '16000') {
    return alert(fm(get('response.data.errors[0].details.error_key.code')(err)), { title: 'Visma' });
  }
  if (get('response.data.errors[0].details.message.code')(err) === '16000') {
    return alert(fm(get('response.data.errors[0].details.message.code')(err)), { title: 'Visma' });
  }
};

// start and end time should be in date time format to use this function
export const getTotalWorkingHoursForProjectOrderEmployees = (startTime, endTime, breakTime) => {
  if (!startTime || !endTime) return;
  let timeDifference = getDifferenceBetweenLocalStrings(endTime, startTime);

  if (breakTime) {
    timeDifference = getDifferenceBetweenTime(timeDifference, breakTime);
  }

  return timeDifference;
};

export const getSmsDescription = (smsText, stringObject) => {
  Object.entries(stringObject).map(([key, value]) => {
    smsText = smsText.replaceAll(key, value);
    return smsText;
  });
  return smsText;
};