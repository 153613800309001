import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFilters, resetFilters } from 'appRedux/actions';
import { multiStateGet } from 'appRedux/reducers/utility';
import { pick, isEmpty } from 'utils/lodash';
import { useLocation } from 'react-router-dom';
import { parseQueryString } from 'utils/string';

export default function useFilters (name) {
  const dispatch = useDispatch();
  const location = useLocation();
  const filters = useSelector((state) => multiStateGet(name, state.filters));

  const setFiltersFn = useCallback((fieldsToSet) => {
    dispatch(setFilters({ ...fieldsToSet, name }));
  }, [name]);

  const resetFiltersFn = useCallback(() => {
    dispatch(resetFilters(name));
  }, [name]);

  const queryParams = useMemo(() => {
    const queryFilterParams = pick(['startDate', 'endDate'])(parseQueryString(location.search));
    return { queryParamsExist: !isEmpty(queryFilterParams), queryFilterParams };
  }, [location]);

  const onPageChange = useCallback((event, newPage) => {
    setFiltersFn({ page: newPage + 1 });
  }, []);

  const onRowsPerPageChange = useCallback((event) => {
    const { value } = event.target;
    setFiltersFn({ perPage: Number(value), page: 1 });
  }, []);

  const onChangeOrder = useCallback((orderBy, event, options) => {
    const { resetOrderValue } = options;
    const order = (orderBy === filters.orderBy && filters.order === 'asc') ? 'desc' : 'asc';
    setFiltersFn({ order, orderBy, page: 1, ...(resetOrderValue && { orderValue: null }) });
  }, [filters.orderBy, filters.order]);

  const handleChangeInput = (event) => {
    const { value, name: fieldName } = event.target;
    setFiltersFn({ [fieldName]: value });
  };

  return {
    filters,
    queryParamsExist: queryParams.queryParamsExist,
    queryFilterParams: queryParams.queryFilterParams,
    setFilters: setFiltersFn,
    resetFilters: resetFiltersFn,
    onChangeOrder,
    onPageChange,
    onRowsPerPageChange,
    handleChangeInput,
  };
}
