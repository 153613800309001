import { getFormattedPaginationFields, getFilterMultipleTagIds } from 'appRedux/selectors/utility';
import {
  add,
  getHourMinutesInUnixTime,
  getStandardDate,
  getTimeFromNumber,
} from 'utils/dateTime';
import { smsDataTypeAPI } from 'utils/enum';
import { getSmsDescription } from 'appRedux/thunks/utility';
import getItemsTotalCosts, { getItemCost } from 'components/Booking/BookingModal/PriceTab/getTotalCosts';
import { pick, isNumber, omit, get, isEmpty } from 'utils/lodash';
import snakify from 'utils/snakify';
import { capitalize } from 'utils/string';

export const getFormattedProposalRequestBody = (proposal, company) => ({
  date: proposal.date,
  requires_bankid_sign: proposal.requiresBankidSign,
  status: proposal.status,
  cancellation_policy: proposal.cancellationPolicy,
  intro_text: proposal.introText,
  company_policy: proposal.companyPolicy,
  flexible: proposal.flexible,
  customer_name: proposal.customerName || proposal.customer.name,
  customer_id: proposal.customer.id || proposal.customerId,
  customer_sequence_num: proposal.customer.sequenceNum,
  service_id: proposal.serviceId,
  expiry_date: proposal.expiryDate,
  time_type: proposal.timeType,
  start_time: getHourMinutesInUnixTime(proposal.startTime),
  end_time: getHourMinutesInUnixTime(proposal.endTime),
  total_hours: getHourMinutesInUnixTime(proposal.totalHours),
  recurring: proposal.recurring ? 'yes' : 'no',
  notes: proposal.notes,
  housework_type: proposal.houseworkType,
  price_type: 'flexible',
  repeat_days: proposal.repeatDays,
  month_repeat: proposal.monthRepeat,
  repeat_interval: proposal.repeatInterval,
  repeat_type: proposal.repeatType,
  proposal_service_attributes: {
    title: proposal.proposalService.title,
    description: proposal.proposalService.description,
    color_code: proposal.proposalService.colorCode,
    color_id: proposal.proposalService.colorId,
    sequence_num: proposal.proposalService.sequenceNum,
  },
  proposal_items_attributes: proposal.proposalItems && proposal.proposalItems.map((item) => ({
    id: proposal.id && item.id,
    item_id: item.itemId,
    discount: item.discount,
    is_housework: item.isHousework,
    housework_type: item.houseworkType,
    per_unit: get('customer.vatIncluded')(proposal) ? Number(item.inclVat) : Number(item.exclVat),
    discount_value: item.itemDiscount,
    fortnox_id: item.fortnoxId,
    visma_item_id: item.vismaItemId,
    reporting_hours: item.reportingHours,
    material_cost: item.materialCost,
    _destroy: item.destroy,
    ...pick(['quantity', 'title', 'vat', 'unit'])(item),
  })),
  proposal_tags_attributes: proposal.proposalTags && proposal.proposalTags.map((tag) => ({
    id: tag.id,
    tag_id: tag.tagId,
    _destroy: tag.destroy,
  })),
  proposal_addresses_attributes: proposal.proposalAddresses
    && proposal.proposalAddresses.map((address) => ({
      ...address,
      _destroy: address.destroy,
    })),
  proposal_reminder_attributes: proposal.proposalReminder ? {
    id: proposal.proposalReminder.id,
    company_id: company.id,
    proposal_id: proposal.id,
    delivery_method: proposal.proposalReminder.deliveryMethod,
    email_title: proposal.proposalReminder.emailTitle,
    days: proposal.proposalReminder.days,
    notification_type: proposal.proposalReminder.notificationType,
    _destroy: proposal.proposalReminder.destroy,
  } : undefined,
  fields_to_include: snakify(proposal.fieldsToInclude),
  send_email: proposal.sendEmail,
  send_sms: proposal.sendSms,
  sms_text: proposal.smsText && getSmsDescription(proposal.smsText, smsDataTypeAPI),
});

export const getFormattedProposalTemplateRequestBody = (proposalTemplate) => ({
  name: proposalTemplate.name,
  service_id: proposalTemplate.serviceId,
  notes: proposalTemplate.notes,
  housework_type: proposalTemplate.houseworkType,
  price_type: 'flexible',
  proposal_template_items_attributes: proposalTemplate.proposalItems
    && proposalTemplate.proposalItems.map((item) => ({
      id: proposalTemplate.id && isNumber(item.id) ? item.id : undefined,
      item_id: item.itemId,
      discount: item.discount,
      is_housework: item.isHousework,
      housework_type: item.houseworkType,
      per_unit: item.perUnit,
      discount_value: item.itemDiscount,
      visma_item_id: item.vismaItemId,
      fortnox_id: item.fortnoxId,
      reporting_hours: item.reportingHours,
      material_cost: item.materialCost,
      _destroy: item.destroy,
      ...pick(['quantity', 'title', 'vat', 'unit'])(item),
    })),
  proposal_template_tags_attributes: proposalTemplate.proposalTags
    && proposalTemplate.proposalTags.map((tag) => ({
      id: proposalTemplate.id && tag.id ? tag.id : undefined,
      name: tag.title,
      _destroy: tag.destroy,
    })),
  cancellation_policy: proposalTemplate.cancellationPolicy,
  intro_text: proposalTemplate.introText,
  company_policy: proposalTemplate.companyPolicy,
});

export const getFormattedProposalData = (record) => {
  const proposalItems = (record.proposalItems || [])
    .map((rest) => ({
      ...rest,
      itemDiscount: rest.discountValue,
      isHouseWork: rest.isHousework,
      integrationDetail: rest.vismaItem,
    }))
    .map((item) => ({
      ...item,
      ...getItemCost(
        { ...item, isHouseWork: item.isHousework },
        { ...record, houseworkType: capitalize(record.houseworkType) },
      ),
    }));
  const proposalPriceAttributes = getItemsTotalCosts(proposalItems, record);
  return {
    ...record,
    proposalService: {
      ...record.proposalService,
      colorCode: record.proposalService.color.code,
      colorCodeId: record.proposalService.color.id,
    },
    proposalTags: record.proposalTags.map((proposalTag) => ({
      ...proposalTag,
      title: proposalTag.tag ? proposalTag.tag.title : proposalTag.name,
    })),
    recurring: record.recurring === 'yes',
    startTime: getTimeFromNumber(record.startTime),
    endTime: record.endTime && getTimeFromNumber(record.endTime),
    totalHours: getTimeFromNumber(record.totalHours),
    proposalItems,
    proposalPriceAttributes,
    reminder: !isEmpty(record.proposalReminder),
  };
};

export const getFormattedFilterParams = (filters = {}) => ({
  page: filters.page || 1,
  per_page: filters.perPage,
  customer_id: filters.customer && filters.customer.id,
  service_id: filters.service && filters.service.id,
  status: filters.status === 'view_all' ? undefined : filters.status,
  signed_status: filters.signedStatus === 'view_all' ? undefined : filters.signedStatus,
  date: filters.startDate,
  end_date: filters.endDate,
  order_by: snakify(filters.orderBy),
  order: filters.order,
  ...(filters.multipleTags?.length > 0 && { tag_ids: getFilterMultipleTagIds(filters.multipleTags) }),
  order_value: filters.orderValue,
});

export const getFormattedProposalActivityResponse = (response) => ({
  ...getFormattedPaginationFields(response),
  records: (response.records || []),
});

export const getFormattedSendTestEmailRequestBody = (data) => ({
  email_address: data.emailAddress,
  fields_to_include: snakify(data.fieldsToInclude),
  intro_text: data.introText,
  cancellation_policy: data.cancellationPolicy,
  company_policy: data.companyPolicy,
});

export const getFormattedProposalTemplateData = (record) => {
  const proposalItems = (record.proposalTemplateItems || [])
    .map((rest) => ({
      ...rest,
      itemDiscount: rest.discountValue,
      isHouseWork: rest.isHousework,
      integrationDetail: rest.vismaItem,
    }))
    .map((item) => ({
      ...item,
      ...getItemCost(
        { ...item, isHouseWork: item.isHousework },
        { ...record, houseworkType: capitalize(record.houseworkType) },
      ),
    }));
  const proposalPriceAttributes = getItemsTotalCosts(proposalItems, record);
  return {
    ...record,
    proposalService: {
      ...record.service,
      colorCode: record.service.color.code,
      colorCodeId: record.service.color.id,
    },
    proposalTags: record.proposalTemplateTags.map((proposalTemplateTag) => ({
      ...proposalTemplateTag,
      title: proposalTemplateTag.tag ? proposalTemplateTag.tag.title : proposalTemplateTag.name,
    })),
    proposalItems,
    proposalPriceAttributes,
  };
};

export const getFormattedCopyProposalData = (record) => {
  const proposalItems = (record.proposalItems || [])
    .map((rest) => ({
      ...rest,
      itemDiscount: rest.discountValue,
      isHouseWork: rest.isHousework,
      integrationDetail: rest.vismaItem,
    }))
    .map((item) => ({
      ...item,
      ...getItemCost(
        { ...item, isHouseWork: item.isHousework },
        { ...record, houseworkType: capitalize(record.houseworkType) },
      ),
    }));
  const proposalPriceAttributes = getItemsTotalCosts(proposalItems, record);

  return {
    ...omit([
      'sequenceNum',
      'id',
      'bookingId',
      'createdAt',
      'updatedAt',
      'status',
      'token',
      'booking',
      'customer',
      'customerId',
      'customerName',
      'proposalAddresses',
    ])(record),
    date: getStandardDate(),
    expiryDate: getStandardDate(add(new Date(), { months: 1 })),
    flexible: record.flexible,
    houseworkType: record.houseworkType,
    monthRepeat: record.monthRepeat,
    notes: record.notes,
    priceType: record.priceType,
    repeatDays: record.repeatDays,
    repeatInterval: record.repeatInterval,
    repeatType: record.repeatType,
    serviceId: record.serviceId,
    timeType: record.timeType,
    proposalService: {
      ...record.proposalService,
      colorCode: record.proposalService.color.code,
      colorCodeId: record.proposalService.color.id,
    },
    proposalTags: record.proposalTags.filter((d) => d.tagId).map((proposalTag) => ({
      tagId: proposalTag.tagId,
      title: proposalTag.tag ? proposalTag.tag.title : proposalTag.name,
    })),
    recurring: record.recurring === 'yes',
    startTime: getTimeFromNumber(record.startTime),
    endTime: record.endTime && getTimeFromNumber(record.endTime),
    totalHours: getTimeFromNumber(record.totalHours),
    proposalItems,
    proposalPriceAttributes,
    proposalReminder: omit(['id', 'createdAt', 'updatedAt'])(record.proposalReminder),
    reminder: Boolean(record.proposalReminder),
  };
};

export const getFormattedCopyProposalDocuments = (documents) => documents.map((doc) => ({
  ...doc,
  id: undefined,
}));

export const getFormattedProposalReminderRequestBody = (record, company) => ({
  proposal_reminder: {
    company_id: company.id,
    proposal_id: record.proposalId,
    days: record.days,
    delivery_method: record.deliveryMethod,
    email_title: record.emailTitle,
    notification_type: record.notificationType,
  },
});
