import React, { useMemo, useCallback } from 'react';
import { string, func, bool, oneOfType, array } from 'prop-types';
import { useSelector } from 'react-redux';
import { Box, DatePicker } from 'components/library';
import { fm } from 'utils/string';
import { getWeekISO } from 'utils/dateTime';
import styles from './styles.module.scss';

export default function DateInput (props) {
  const language = useSelector((state) => state.profile.language);
  const weekStart = useMemo(() => ({ se: 1, en: 0 }));

  const renderDay = useCallback((date, selectedDate, dayInCurrentMonth, day) => (
    <Box display="flex">
      {date.getDay() === weekStart[language] && (
        <div className={styles.materialDatePickerWeek}>
          {getWeekISO(date, { weekStartsOn: 1, firstWeekContainsDate: 4 })}
        </div>
      )}
      {day}
    </Box>
  ), []);

  const slotProps = useMemo(() => ({
    textField: { size: props.size, helperText: props.helperText, error: props.error, fullWidth: props.fullWidth },
  }), [props.size, props.helperText]);

  return (
    <DatePicker
      {...props}
      value={props.value ? new Date(props.value) : null}
      renderDay={props.showWeekNumber ? renderDay : undefined}
      label={fm(`${props.labelId}`, props.defaultLabel)}
      slotProps={slotProps}
    />
  );
}

DateInput.propTypes = {
  onMonthChange: func,
  showWeekNumber: bool,
  name: string,
  value: string,
  labelId: string,
  defaultLabel: string,
  minDate: string,
  maxDate: string,
  disabled: bool,
  error: bool,
  openTo: string,
  helperText: string,
  size: string,
  views: oneOfType([string, array]),
};

DateInput.defaulProps = {
  showWeekNumber: false,
  size: 'small',
};
