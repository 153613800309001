import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from 'appRedux/api/axiosBaseQuery';

export const applicationApi = createApi({
  reducerPath: 'applicationApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getWebAppVersion: builder.query({
      query: () => ({ url: '/api/v1/profile/web_app_versions', method: 'GET' }),
    }),
    updateWebAppVersion: builder.mutation({
      query: (data) => ({ url: '/api/v1/profile/read_version_updates', method: 'PUT', data }),
    }),
  }),
});

export const { useGetWebAppVersionQuery, useUpdateWebAppVersionMutation } = applicationApi;
