import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from 'appRedux/api/axiosBaseQuery';
import { getFormattedSmsTemplate } from './selector';

export const smsApi = createApi({
  reducerPath: 'smsApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['SmsTemplates', 'ProposalSmsStatus'],
  endpoints: (builder) => ({
    getSmsTemplates: builder.query({
      providesTags: ['SmsTemplates'],
      query: () => ({ url: '/api/v3/sms_templates', method: 'GET' }),
      transformResponse: (response) => getFormattedSmsTemplate(response.data.smsTemplates) || {},
    }),
    getProposalSmsStatus: builder.query({
      providesTags: ['ProposalSmsStatus'],
      query: () => ({ url: '/api/v3/company/proposal_sms_status', method: 'GET' }),
      transformResponse: (response) => response.data.proposalSmsStatus || false,
    }),
  }),
});

export const {
  useGetSmsTemplatesQuery,
  useGetProposalSmsStatusQuery,
} = smsApi;
