import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MuiSelect from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

const Select = (props) => {
  const { label, helperText, error, size, children } = props;

  return (
    <FormControl size={size || 'small'} fullWidth error={!!error}>
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        {...props}
        label={label}
      >
        {children}
      </MuiSelect>
      {error && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

Select.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  label: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  size: PropTypes.string,
};

export default Select;
