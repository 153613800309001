import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  requestCreateProposal,
  requestProposals,
  requestProposal,
  requestUpdateProposal,
  requestDeleteProposals,
  requestProposalPolicies,
  requestCreateProposalPolicies,
  requestUpdateProposalPolicies,
  requestProposalActivity,
  requestDeleteProposalDocument,
  requestCreateProposalTag,
  requestCreateProposalTemplateTag,
  requestProposalTemplates,
  requestSendTestEmail,
  requestProposalTemplate,
  requestCreateProposalTemplate,
  requestUpdateProposalTemplate,
  requestDeleteProposalTemplates,
  requestProposalTemplateForCreateProposal,
  requestDeleteProposalTemplateDocument,
  requestProposalToCopy,
  requestProposalMessagesForAdmin,
  requestPostProposalMessagesForAdmin,
  requestDeleteProposalMessageForAdmin,
  requestMarkReadProposalMessageForAdmin,
} from './thunk';
import { requestCreateProposalReminder, requestDeleteProposalReminder, requestUpdateProposalReminder } from './proposalReminder';

const proposals = createSlice({
  name: 'proposals',
  initialState: {
    activeTab: 0,
    records: [],
    proposal: {},
    proposalTemplates: {
      proposalTemplate: {},
      proposalTemplateDocuments: {
        records: [],
      },
      records: [],
    },
    proposalDocuments: {
      records: [],
    },
    refreshAllProposals: {},
    showProposalDialog: false,
    proposalPolicy: {},
    proposalActivity: {},
    customerProposal: {},
    companyDetailsAndPolicy: {},
    messages: [],
    fsip: false,
  },
  reducers: {
    setProposalsFields (state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    setProposalTemplateFields (state, action) {
      return {
        ...state,
        proposalTemplates: {
          ...state.proposalTemplates,
          ...action.payload,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestProposalMessagesForAdmin.fulfilled, (state, action) => ({
      ...state,
      messages: action.payload,
    }));
    builder.addCase(requestPostProposalMessagesForAdmin.fulfilled, (state, action) => ({
      ...state,
      messages: [...state.messages, action.payload],
    }));
    builder.addCase(requestDeleteProposalMessageForAdmin.fulfilled, (state, action) => ({
      ...state,
      messages: state.messages.map((message) => {
        if (message.id === action.payload.id) {
          return action.payload;
        }
        return message;
      }),
    }));
    builder.addCase(requestMarkReadProposalMessageForAdmin.fulfilled, (state, action) => ({
      ...state,
      messages: state.messages.map((message) => {
        if (message.id === action.payload.id) {
          return action.payload;
        }
        return message;
      }),
    }));
    builder.addCase(requestProposal.fulfilled, (state, action) => ({
      ...state,
      proposal: action.payload.proposal,
      proposalDocuments: action.payload.proposalDocuments,
      signature: action.payload.signature,
    }));
    builder.addCase(requestProposalToCopy.fulfilled, (state, action) => ({
      ...state,
      proposal: {
        ...action.payload.proposal,
        documentIds: action.payload.proposalDocuments.records.map((record) => record.blobId),
      },
      proposalDocuments: action.payload.proposalDocuments,
    }));
    builder.addCase(requestCreateProposalTag.fulfilled, (state, action) => ({
      ...state,
      proposal: action.payload.proposal,
    }));
    builder.addCase(requestProposals.fulfilled, (state, action) => ({
      ...state,
      records: action.payload.records,
    }));
    builder.addCase(requestDeleteProposals.fulfilled, (state) => ({
      ...state,
      refreshAllProposals: {},
      selectedProposalIds: [],
    }));
    builder.addCase(requestDeleteProposalDocument.fulfilled, (state, action) => ({
      ...state,
      proposalDocuments: {
        records: action.payload.records,
      },
    }));
    builder.addCase(requestProposalPolicies.fulfilled, (state, action) => ({
      ...state,
      proposalPolicy: action.payload,
    }));
    builder.addCase(requestSendTestEmail.fulfilled, (state) => ({
      ...state,
      showTestEmailDialog: false,
      fsip: false,
    }));
    builder.addCase(requestProposalActivity.fulfilled, (state, action) => ({
      ...state,
      proposalActivity: action.payload,
    }));
    builder.addCase(requestProposalTemplates.fulfilled, (state, action) => ({
      ...state,
      proposalTemplates: {
        ...state.proposalTemplates,
        records: action.payload.records,
      },
    }));
    builder.addCase(requestDeleteProposalReminder.fulfilled, (state) => ({
      ...state,
      showProposalReminderDialog: false,
      fsip: false,
      refreshAllProposals: {},
    }));
    builder.addCase(requestProposalTemplate.fulfilled, (state, action) => ({
      ...state,
      proposalTemplates: {
        ...state.proposalTemplates,
        proposalTemplate: action.payload.proposalTemplate,
        proposalTemplateDocuments: action.payload.proposalTemplateDocuments,
      },
    }));
    builder.addCase(requestProposalTemplateForCreateProposal.fulfilled, (state, action) => ({
      ...state,
      proposalDocuments: {
        records: action.payload.proposalTemplateDocuments.records.map(
          (record) => ({ ...record, id: undefined }),
        ),
      },
      proposal: {
        ...state.proposal,
        proposalItems: action.payload.proposalTemplate.proposalItems,
        companyPolicy: action.payload.proposalTemplate.companyPolicy,
        introText: action.payload.proposalTemplate.introText,
        documentIds: action.payload.proposalTemplateDocuments.records.map((record) => record.blobId),
      },
    }));
    builder.addCase(requestDeleteProposalTemplateDocument.fulfilled, (state, action) => ({
      ...state,
      proposalTemplates: {
        ...state.proposalTemplates,
        proposalTemplateDocuments: {
          records: action.payload.records,
        },
      },
    }));
    builder.addCase(requestDeleteProposalTemplates.fulfilled, (state) => ({
      ...state,
      proposalTemplates: {
        ...state.proposalTemplates,
        refreshAllProposalTemplates: {},
        selectedProposalTemplateIds: [],
      },
    }));
    builder.addCase(requestCreateProposalTemplateTag.fulfilled, (state, action) => ({
      ...state,
      proposalTemplates: {
        ...state.proposalTemplates,
        proposalTemplate: action.payload.proposalTemplate,
      },
    }));
    builder.addMatcher(
      isAnyOf(requestCreateProposalTemplate.fulfilled, requestUpdateProposalTemplate.fulfilled),
      (state) => ({
        ...state,
        proposalTemplates: {
          ...state.proposalTemplates,
          showProposalTemplateDialog: false,
          refreshAllProposalTemplates: {},
          fsip: false,
          proposalTemplate: {},
          openedProposalTemplateId: null,
        },
      }),
    );
    builder.addMatcher(
      isAnyOf(requestCreateProposalTemplate.rejected, requestUpdateProposalTemplate.rejected),
      (state) => ({
        ...state,
        proposalTemplates: {
          ...state.proposalTemplates,
          fsip: false,
        },
      }),
    );
    builder.addMatcher(
      isAnyOf(requestCreateProposal.fulfilled, requestUpdateProposal.fulfilled),
      (state) => ({
        ...state,
        openedProposalId: null,
        showProposalDialog: false,
        proposal: {},
        refreshAllProposals: {},
        proposalActivity: {
          records: [],
        },
        proposalDocuments: {
          records: [],
        },
        showEmailPreviewDialog: false,
        fsip: false,
        proposalTemplates: {
          ...state.proposalTemplates,
          proposalTemplateDocuments: {
            records: [],
          },
        },
      }),
    );
    builder.addMatcher(
      isAnyOf(requestCreateProposalPolicies.fulfilled, requestUpdateProposalPolicies.fulfilled),
      (state) => ({
        ...state,
        showProposalPolicyDialog: false,
        fsip: false,
      }),
    );
    builder.addMatcher(
      isAnyOf(requestCreateProposalReminder.fulfilled, requestUpdateProposalReminder.fulfilled),
      (state) => ({
        ...state,
        showProposalPolicyDialog: false,
        fsip: false,
        refreshAllProposals: {},
      }),
    );
    builder.addMatcher(
      isAnyOf(requestCreateProposal.rejected, requestUpdateProposal.rejected,
        requestCreateProposalReminder.rejected, requestUpdateProposalReminder.rejected),
      (state) => ({
        ...state,
        fsip: false,
      }),
    );
  },
});

const { reducer, actions } = proposals;

export const { setProposalsFields, setProposalTemplateFields } = actions;

export default reducer;
