import { createSlice } from '@reduxjs/toolkit';
import {
  requestUsers,
  requestUnpaidInvoiceHistories,
  requestPaidInvoiceHistories,
  requestGetSubscriptionTiers,
  requestSmsBillingHistories,
  requestBankIdBillingHistories,
  requestSeatBillingHistories,
  requestgetRenewalCostEstimation,
  requestChangeBillingCycle,
  requestSubmitContract,
  requestGetInvoiceDetails,
  requestUpdateInvoiceDetails,
  requestGetCostEstimation,
  requestUpgradeSubscriptionTier,
  requestUploadPaymentReceipt,
  requestGetDiscountDetails,
} from './thunks';

const billing = createSlice({
  name: 'billing',
  initialState: {
    seats: [],
    currentSubscription: {},
    paidInvoiceHistories: {
      records: [],
    },
    unpaidInvoiceHistories: {
      beforeRecords: [],
      afterRecords: [],
    },
    billingSeatHistories: {
      records: [],
    },
    billingSmsHistories: {
      records: [],
    },
    billingBankIdHistories: {
      records: [],
    },
    renewalCostEstimation: {},
    subscriptionTiers: [],
    contractData: {},
    selectedPlan: {},
    invoiceDetails: {},
    costEstimation: {},
    discountDetails: {},
    errors: {},
    isLoadingSubscription: true,
  },
  reducers: {
    setBillingFields (state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestUsers.fulfilled, (state, action) => ({
      ...state,
      seats: action.payload.records,
    }));
    builder.addCase(requestPaidInvoiceHistories.fulfilled, (state, action) => ({
      ...state,
      paidInvoiceHistories: {
        ...(action.payload.isBefore
          ? { beforeRecords: action.payload.records }
          : { afterRecords: action.payload.records }
        ),
      },
    }));
    builder.addCase(requestUnpaidInvoiceHistories.fulfilled, (state, action) => ({
      ...state,
      unpaidInvoiceHistories: {
        records: action.payload.records,
      },
    }));
    builder.addCase(requestGetSubscriptionTiers.fulfilled, (state, action) => ({
      ...state,
      subscriptionTiers: action.payload,
    }));
    builder.addCase(requestSmsBillingHistories.fulfilled, (state, action) => ({
      ...state,
      billingSmsHistories: {
        records: action.payload.records,
      },
    }));
    builder.addCase(requestBankIdBillingHistories.fulfilled, (state, action) => ({
      ...state,
      billingBankIdHistories: {
        records: action.payload.records,
      },
    }));
    builder.addCase(requestSeatBillingHistories.fulfilled, (state, action) => ({
      ...state,
      billingSeatHistories: {
        records: action.payload.records,
      },
    }));
    builder.addCase(requestgetRenewalCostEstimation.fulfilled, (state, action) => ({
      ...state,
      renewalCostEstimation: action.payload,
    }));
    builder.addCase(requestChangeBillingCycle.fulfilled, (state) => ({
      ...state,
      showUpdateBillingCycleModal: false,
    }));
    builder.addCase(requestSubmitContract.fulfilled, (state) => ({
      ...state,
      fsip: false,
    }));
    builder.addCase(requestSubmitContract.rejected, (state) => ({
      ...state,
      fsip: false,
    }));
    builder.addCase(requestGetInvoiceDetails.fulfilled, (state, action) => ({
      ...state,
      invoiceDetails: action.payload,
    }));
    builder.addCase(requestGetCostEstimation.fulfilled, (state, action) => ({
      ...state,
      costEstimation: action.payload,
    }));
    builder.addCase(requestUpdateInvoiceDetails.fulfilled, (state, action) => ({
      ...state,
      invoiceDetails: {
        ...state.invoiceDetails,
        ...action.payload,
      },
      openInvoiceDetailsModal: false,
    }));
    builder.addCase(requestUpgradeSubscriptionTier.rejected, (state, action) => {
      if (action.payload.subscriptionTierId) {
        return {
          ...state,
          errors: {
            ...state.errors,
            [action.payload.subscriptionTierId]: action.payload.error,
          },
        };
      }
      return state;
    });
    builder.addCase(requestUploadPaymentReceipt.fulfilled, (state) => ({
      ...state,
      showPaidNowDocumentUploadModal: false,
    }));
    builder.addCase(requestGetDiscountDetails.fulfilled, (state, action) => ({
      ...state,
      discountDetails: action.payload,
    }));
  },
});

const { reducer, actions } = billing;

export const { setBillingFields } = actions;

export default reducer;
