import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  requestCreateEmployeeActivity,
  requestUpdateEmployeeActivity,
  requestEmployeeActivity,
  requestDeleteEmployeeActivity,
  requestDeleteEmployeeActivities,
  requestEmployeeActivities,
} from './thunk';

const employeeActivity = createSlice({
  name: 'employeeActivity',
  initialState: {
    records: [],
    employeeActivity: {},
    refreshAllEmployeeActivity: {},
    showEmployeeActivityDialog: false,
    selectedActivityIds: [],
    openedActivityId: null,
    fsip: false,
    openedFromProject: false,
    openedFromOrder: false,
    createdActivity: null,
  },
  reducers: {
    setEmployeeActivityFields (state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestEmployeeActivity.fulfilled, (state, action) => ({
      ...state,
      employeeActivity: action.payload.employeeActivity,
      fsip: false,
    }));
    builder.addCase(requestEmployeeActivities.fulfilled, (state, action) => ({
      ...state,
      records: action.payload.records,
    }));
    builder.addCase(requestCreateEmployeeActivity.fulfilled, (state, action) => ({
      ...state,
      createdActivity: state.openedFromOrder || state.openedFromProject
        ? action.payload : null,
    }));
    builder.addCase(requestDeleteEmployeeActivity.fulfilled, (state) => ({
      ...state,
      refreshAllEmployeeActivity: {},
      showEmployeeActivityDialog: false,
      employeeActivity: {},
      openedActivityId: null,
      fsip: false,
    }));
    builder.addCase(requestDeleteEmployeeActivities.fulfilled, (state) => ({
      ...state,
      refreshAllEmployeeActivity: {},
      openedActivityId: null,
      fsip: false,
      selectedActivityIds: [],
    }));
    builder.addMatcher(
      isAnyOf(requestCreateEmployeeActivity.fulfilled, requestUpdateEmployeeActivity.fulfilled),
      (state) => ({
        ...state,
        openedActivityId: null,
        showEmployeeActivityDialog: false,
        employeeActivity: {},
        refreshAllEmployeeActivity: {},
        fsip: false,
      }),
    );
  },
});

const { reducer, actions } = employeeActivity;

export const { setEmployeeActivityFields } = actions;

export default reducer;
