import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  addPredefinedChecklistToProject,
  requestCreateProjectTag,
  requestProjects,
  requestCreateProject,
  requestProject,
  requestUpdateProject,
  requestProjectOrders,
  requestDeleteProjects,
  requestProjectToCopy,
  requestReactivateProject,
  requestCloseProject,
  requestProjectActivity,
  requestDeleteProject,
  requestProjectsStats,
  requestSendProjectEmail,
  requestSendProjectTestEmail,
  requestSendProjectSms,
  requestDeleteProjectDocument,
  requestProjectSmsEmailHistory,
  requestUpdateRecurringOptionsForProject,
  requestCheckProjectOrdersOnUpdateRecurringRules,
} from './thunk';

const projects = createSlice({
  name: 'projects',
  initialState: {
    records: [],
    projectDialogError: null, // error message for project dialog from backend
    project: {
      projectEmployees: [],
      projectItems: [],
      repeatDays: [],
      projectChecklists: [],
      projectRecurringRule: {},
      isRecurring: false,
      customer: {
        customerAddresses: [],
      },
      employeeActivityJunctions: [],
    },
    projectSmsEmailHistory: {
      sms: [],
      email: [],
    },
    fsip: false,
    selectedProjectIds: [],
    refreshAllProjects: {},
    showProjectDialog: false,
    showChangeWeekDialog: false,
    showChangeStartEndDateDialog: false,
    projectDocuments: {
      records: [],
    },
    orders: {
      records: [],
    },
    activity: {
      records: [],
    },
    projectStats: {},
  },
  reducers: {
    setProjectsFields (state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addPredefinedChecklistToProject.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
    }));
    builder.addCase(requestCreateProjectTag.fulfilled, (state, action) => ({
      ...state,
      project: action.payload.project,
    }));
    builder.addCase(requestProjects.fulfilled, (state, action) => ({
      ...state,
      records: action.payload.records,
      selectedProjectIds: [],
    }));
    builder.addCase(requestSendProjectEmail.fulfilled, (state) => ({
      ...state,
      showEmailPreviewDialog: false,
      previewProjectIds: [],
      fsip: false,
    }));
    builder.addCase(requestProject.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
      project: action.payload.project,
      fsip: false,
    }));
    builder.addCase(requestProjectsStats.fulfilled, (state, action) => ({
      ...state,
      projectStats: action.payload.projectStats,
    }));
    builder.addCase(requestProjectSmsEmailHistory.fulfilled, (state, action) => ({
      ...state,
      projectSmsEmailHistory: {
        ...state.projectSmsEmailHistory,
        ...action.payload,
      },
    }));
    builder.addCase(requestDeleteProjects.fulfilled, (state) => ({
      ...state,
      refreshAllProjects: {},
      selectedProjectIds: [],
      showProjectDialog: false,
      openedProjectId: null,
    }));
    builder.addCase(requestDeleteProjectDocument.fulfilled, (state, action) => ({
      ...state,
      projectDocuments: action.payload.projectDocuments,
    }));
    builder.addCase(requestProjectOrders.fulfilled, (state, action) => ({
      ...state,
      orders: {
        ...state.orders,
        records: action.payload.records,
      },
    }));
    builder.addCase(requestCheckProjectOrdersOnUpdateRecurringRules.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
    }));
    builder.addCase(requestProjectToCopy.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
      openedProjectId: null,
    }));
    builder.addCase(requestSendProjectTestEmail.fulfilled, (state) => ({
      ...state,
      fsip: false,
    }));
    builder.addCase(requestReactivateProject.fulfilled, (state) => ({
      ...state,
      showReactivateProjectDialog: false,
      refreshAllProjects: {},
      selectedProjectIds: [],
      fsip: false,
    }));
    builder.addCase(requestSendProjectSms.fulfilled, (state) => ({
      ...state,
      fsip: false,
    }));
    builder.addCase(requestCloseProject.fulfilled, (state) => ({
      ...state,
      showProjectDialog: false,
      showCloseProjectDialog: false,
      refreshAllProjects: {},
      selectedProjectIds: [],
      fsip: false,
    }));
    builder.addCase(requestDeleteProject.fulfilled, (state) => ({
      ...state,
      showProjectDialog: false,
      showDeleteProjectDialog: false,
      refreshAllProjects: {},
      selectedProjectIds: [],
      openedProjectId: null,
      project: {},
      fsip: false,
    }));
    builder.addCase(requestProjectActivity.fulfilled, (state, action) => ({
      ...state,
      activity: {
        records: action.payload.records,
      },
    }));
    builder.addCase(requestUpdateRecurringOptionsForProject.fulfilled, (state) => ({
      ...state,
      fsip: false,
      showChangeWeekDialog: false,
      showChangeStartEndDateDialog: false,
      showRepeatIntervalDialog: false,
      refreshAllProjects: {},
    }));
    builder.addMatcher(
      isAnyOf(requestCreateProject.fulfilled),
      (state, action) => ({
        ...state,
        refreshAllProjects: {},
        fsip: false,
        showProjectUpdateOptionsDialog: false,
        openedProjectId: action.payload.project.id,
      }),
    );
    builder.addMatcher(
      isAnyOf(requestUpdateProject.fulfilled),
      (state, action) => ({
        ...state,
        refreshAllProjects: {},
        fsip: false,
        showProjectUpdateOptionsDialog: false,
        openedProjectId: action.payload.project.id,
      }),
    );
    builder.addMatcher(
      isAnyOf(requestUpdateProject.rejected, requestCreateProject.rejected),
      (state, action) => ({
        ...state,
        refreshAllProjects: {},
        fsip: false,
        projectDialogError: action.payload.projectDialogError,
        showProjectUpdateOptionsDialog: false,
      }),
    );
  },
});

const { reducer, actions } = projects;

export const { setProjectsFields } = actions;

export default reducer;
