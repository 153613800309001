import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from 'appRedux/api/axiosBaseQuery';

export const companySettingsApi = createApi({
  reducerPath: 'companySettingsApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['CompanySettingsStorageStats'],
  endpoints: (builder) => ({
    getStorageStats: builder.query({
      providesTags: ['CompanySettingsStorageStats'],
      query: () => ({ url: '/api/v3/company_settings/document_limit', method: 'GET' }),
      transformResponse: (response) => response.data || {},
    }),
  }),
});

export const { useLazyGetStorageStatsQuery, useGetStorageStatsQuery } = companySettingsApi;
