import axios from 'utils/axios';
import { userRoles, names } from 'utils/enum';
import { isArray, get } from 'utils/lodash';
import { multiStateGet } from 'appRedux/reducers/utility';
import {
  setAreaSuggestions,
  setCustomerAreaSuggestions,
  setCustomerZipcodeSuggestions,
  setEmployeeActivitySuggestions,
  setSkillsSuggestions,
  setCustomerSuggestions,
  setFortnoxItemSuggestions,
  setVismaItemSuggestions,
  setSnackbarError,
  setSupervisorSuggestions,
  setEmployeeSuggestions,
  setServiceSuggestions,
  setItemSuggestions,
  setTaskSuggestions,
  setTaskCategorySuggestions,
  setPredefinedChecklistSuggestions,
  setChecklistCollectionSuggestions,
  setKeySuggestions,
  setVismaItemNumberSuggestions,
  setUserGroupSuggestions,
  setDepartmentSuggestions,
  setSuperAdminUserSuggestions,
  setEmployeeViewActivitySuggestions,
} from 'appRedux/actions';
import { setProposalTemplateFields } from 'appRedux/owner/proposals';
import {
  getFormatedTaskSearchData,
  getFormattedChecklistObjectsData,
  getFormattedCustomerSuggestionsData,
  getFormattedItemData,
  getFormattedProposalTemplateRecords,
} from 'appRedux/thunks/autocomplete/selector';

export const requestSupervisorSuggestions = (text, exceptionIds) => async (dispatch, getState) => {
  const { filters, application: { uiState } } = getState();
  try {
    const filtersData = multiStateGet(names.calendar, filters);
    const query = {
      query: text, role: userRoles.employee,
      is_supervisor: true, ids: exceptionIds,
      company_ids: uiState.name === names.calendar && filtersData.showFilters
        ? filtersData.companyIds
        : undefined,
    };
    const { data } = await axios.get('/api/v1/users/search', { params: query });
    const records = isArray(data.data.records) ? data.data.records : [];
    dispatch(setSupervisorSuggestions({ records }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.users.search.error'));
  }
};

export const requestEmployeeSuggestions = (text, exceptionIds) => async (dispatch, getState) => {
  const { filters, application: { uiState } } = getState();
  try {
    const filtersData = multiStateGet(names.calendar, filters);
    const query = {
      query: text,
      role: userRoles.employee,
      ids: exceptionIds,
      company_ids: uiState.name === names.calendar && filtersData.showFilters
        ? filtersData.companyIds
        : undefined,
    };
    const { data } = await axios.get('/api/v1/users/search', { params: query });
    const records = isArray(data.data.records) ? data.data.records : [];
    dispatch(setEmployeeSuggestions({ records }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.users.search.error'));
  }
};

export const requestCustomerSuggestions = (q) => async (dispatch, getState) => {
  const { profile: { company }, filters, application: { uiState } } = getState();
  try {
    const filtersData = multiStateGet(names.calendar, filters);
    const query = {
      params: {
        query: q,
        per_page: 10,
        company_ids: uiState.name === names.calendar && filtersData.showFilters
          ? filtersData.companyIds
          : undefined,
      },
    };
    const response = await axios.get('/api/v3/customers/search', query);
    const records = getFormattedCustomerSuggestionsData(response.data.data.records, company);
    dispatch(setCustomerSuggestions({ records }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.users.search.error'));
  }
};

export const requestIntegratedItemSuggestions = (text) => async (dispatch, getState) => {
  const { profile } = getState();
  try {
    const query = { query: encodeURIComponent(text) };
    const response = await axios.get('/api/v1/items/filter_integrated_items', { params: query });
    const records = getFormattedItemData(response.data.data);
    if (profile.company.fortnoxIntegrated) {
      dispatch(setFortnoxItemSuggestions({ records }));
    } else {
      dispatch(setVismaItemSuggestions({ records }));
    }
  } catch (err) {
    dispatch(setSnackbarError('snackbar.users.search.error'));
  }
};

export const requestVismaItemNumberSuggestions = (text) => async (dispatch) => {
  try {
    const query = { query: text, per_page: 10, page: 1 };
    const response = await axios.get('/api/v1/items/search_visma_number', { params: query });
    const records = isArray(response.data.data.records)
      ? response.data.data.records.map((r) => ({ ...r, id: r.vismaId }))
      : [];
    dispatch(setVismaItemNumberSuggestions({ records }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.users.search.error'));
  }
};

export const requestServiceSuggestions = (text) => async (dispatch, getState) => {
  const { filters, application: { uiState } } = getState();
  try {
    const filtersData = multiStateGet(names.calendar, filters);
    const query = {
      params: {
        query: encodeURIComponent(text),
        company_ids: uiState.name === names.calendar && filtersData.showFilters
          ? filtersData.companyIds
          : undefined,
      },
    };
    const { data } = await axios.get('/api/v3/services/search', query);
    const records = isArray(data.data) ? data.data : [];
    dispatch(setServiceSuggestions({ records }));
  } catch (err) {
    console.log(err); // eslint-disable-line
  }
};

export const requestSkillsSuggestions = (skill, skillIds) => async (dispatch) => {
  try {
    if (!skill) return;
    const params = { skill, ids: skillIds };
    const response = await axios.get('/api/v3/skills/search', { params });
    dispatch(setSkillsSuggestions({
      records: response.data.data.skills || [],
    }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar_skills_error'));
  }
};

export const requestItemSuggestions = (query) => async (dispatch) => {
  try {
    const params = { query, page: 1, per_page: 10 };
    const response = await axios.get('/api/v1/items/search', { params });
    const records = get('data.data.records')(response) || [];
    dispatch(setItemSuggestions({ records }));
  } catch (err) {
    console.log(err); // eslint-disable-line
  }
};

export const requestSearchTasks = (query, ids) => async (dispatch) => {
  try {
    if (query) {
      const params = { query, ids };
      const response = await axios.get('/api/v3/tasks/search', { params });
      const records = response.data.data.length ? getFormatedTaskSearchData(response.data.data) : [];
      dispatch(setTaskSuggestions({ records }));
    } else {
      dispatch(setTaskSuggestions({ records: [] }));
    }
  } catch (err) {
    console.log(err); // eslint-disable-line
  }
};

export const requestSearchTaskCategory = (text) => async (dispatch) => {
  try {
    if (text) {
      const query = encodeURIComponent(text);
      const response = await axios.get(`/api/v3/task_categories/search?query=${query}`);
      const records = response.data.data.length ? response.data.data : [];
      dispatch(setTaskCategorySuggestions({ records }));
    } else {
      dispatch(setTaskCategorySuggestions({ records: [] }));
    }
  } catch (err) {
    console.log(err); //eslint-disable-line
  }
};

export const requestSearchBookingChecklistObjects = (query, searchType, ids) => async (dispatch) => {
  try {
    if (query) {
      const params = { query, search_by: searchType, except_task_category_ids: ids };
      const response = await axios.get('/api/v3/checklists/predefined_search', { params });
      const records = getFormattedChecklistObjectsData(response.data.data.results, searchType);
      dispatch(setPredefinedChecklistSuggestions({ records }));
    } else {
      dispatch(setPredefinedChecklistSuggestions({ records: [] }));
    }
  } catch (err) {
    console.log(err); //eslint-disable-line
  }
};

export const requestChecklistCollectionSuggestions = (query, exceptionIds) => async (dispatch) => {
  try {
    if (!query) return;
    const params = { title: query, ids: exceptionIds };
    const response = await axios.get('/api/v3/checklist_collections/search', { params });
    const records = isArray(response.data.data) ? response.data.data : [];
    dispatch(setChecklistCollectionSuggestions({ records }));
  } catch (err) {
    console.log(err); //eslint-disable-line
  }
};

export const requestKeysSuggestions = (query, exceptionIds) => async (dispatch) => {
  try {
    if (!query) return;
    const params = { query, except_customer: true, per_page: 10, ids: exceptionIds };
    const response = await axios.get('/api/v3/key_managements/search', { params });
    dispatch(setKeySuggestions({ records: response.data.data.records }));
  } catch (err) {
    console.log(err); //eslint-disable-line
  }
};

export const requestUserGroupSuggestions = (query) => async (dispatch, getState) => {
  const { filters, application: { uiState } } = getState();
  try {
    if (!query) return;
    const filtersData = multiStateGet(names.calendar, filters);
    const params = {
      query, per_page: 10,
      page: 1,
      company_ids: uiState.name === names.calendar && filtersData.showFilters
        ? filtersData.companyIds
        : undefined,
    };
    const response = await axios.get('/api/v3/user_groups/search', { params });
    dispatch(setUserGroupSuggestions({ records: response.data.data.records }));
  } catch (err) {
    console.log(err); //eslint-disable-line
  }
};

export const requestDepartmentSuggestions = (query) => async (dispatch, getState) => {
  const { filters } = getState();
  try {
    if (!query) return;
    const filtersData = multiStateGet(names.calendar, filters);
    const params = { query, per_page: 10, page: 1, company_ids: filtersData.companyIds  };
    const response = await axios.get('/api/v3/departments/search', { params });
    dispatch(setDepartmentSuggestions({ records: response.data.data.records }));
  } catch (err) {
    console.log(err); //eslint-disable-line
  }
};

export const requestAreaSuggestions = (query) => async (dispatch, getState) => {
  const { filters } = getState();
  try {
    if (!query) return;
    const filtersData = multiStateGet(names.calendar, filters);
    const params = { query, per_page: 10, page: 1, company_ids: filtersData.companyIds };
    const response = await axios.get('/api/v3/areas/search', { params });
    dispatch(setAreaSuggestions({ records: response.data.data.records }));
  } catch (err) {
    console.log(err); //eslint-disable-line
  }
};

export const requestCustomerAreaSuggestions = (area) => async (dispatch, getState) => {
  const { filters } = getState();
  try {
    if (!area) return;
    const filtersData = multiStateGet(names.calendar, filters);
    const params = { area, company_ids: filtersData.companyIds };
    const response = await axios.get('/api/v3/customers/customer_areas', { params });
    dispatch(setCustomerAreaSuggestions({
      records: (response.data.data.areas || []).map((area) => ({ title: area.area })),
    }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar_customer_area_error'));
  }
};

export const requestCustomerZipcodeSuggestions = (zipcode) => async (dispatch) => {
  try {
    const params = { zip_code: zipcode };
    const response = await axios.get('/api/v3/customers/customer_zip_codes', { params });
    dispatch(setCustomerZipcodeSuggestions({
      records: (response.data.data.zipCodes || []).map((code) => ({ title: code })),
    }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar_customer_zip_code_error'));
  }
};

export const requestSuperAdminUserSuggestions = (query) => async (dispatch) => {
  try {
    if (!query) return;
    const params = { query, per_page: 10, page: 1 };
    const response = await axios.get('/api/v3/super_admin/users', { params });
    dispatch(setSuperAdminUserSuggestions({ records: response.data.data.records }));
  } catch (err) {
    console.log(err); //eslint-disable-line
  }
};

export const requestProposalTemplateSuggestions = (text) => async (dispatch) => {
  try {
    const { data } = await axios.get(`/api/v3/proposal_templates/search?name=${text}`);
    const records = isArray(data.data.proposalTemplates)
      ? getFormattedProposalTemplateRecords(data.data.proposalTemplates)
      : [];
    dispatch(setProposalTemplateFields({ templateSuggestions: { records } }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.template.search.error'));
  }
};

export const requestEmployeeActivitySuggestions = (query, exceptionIds) => async (dispatch) => {
  try {
    if (!query) return;
    const params = { query, ids: exceptionIds };
    const response = await axios.get('/api/v3/employee_activity/search', { params });
    dispatch(setEmployeeActivitySuggestions({ records: response.data.data.records }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar_activity_search_error'));
  }
};

export const requestEmployeeViewActivitySuggestions = (query, exceptionIds) => async (dispatch) => {
  try {
    if (!query) return;
    const params = { query, ids: exceptionIds };
    const response = await axios.get('/api/v3/employee/employee_activity/search', { params });
    dispatch(setEmployeeViewActivitySuggestions({ records: response.data.data.records }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar_activity_search_error'));
  }
};
