import { createSlice } from '@reduxjs/toolkit';
import { getStandardDate, add, formatDate, formatStrings } from 'utils/dateTime';
import {
  requestProjects,
  requestProject,
  requestAddOrderTime,
  requestSearchEmployeeProjectCustomers,
  requestProjectOrders,
} from './thunk';

const projects = createSlice({
  name: 'projects',
  initialState: {
    filters: {
      startDate: getStandardDate(),
      endDate: formatDate(add(new Date(), { months: 1 }), formatStrings.filtersDate),
      supervisorOnly: false,
    },
    records: [],
    project: {
      service: {},
      customer: {},
    },
    orders: [],
    customers: {},
    employeeActivityJunctions: [],
    employeeActivityJunction: {},
  },
  reducers: {
    setEmployeeProjectFields: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(requestProjects.fulfilled, (state, action) => ({
      ...state,
      records: action.payload.records,
    }));
    builder.addCase(requestProject.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
    }));
    builder.addCase(requestAddOrderTime.fulfilled, (state) => ({
      ...state,
      showAddTimeDialog: false,
    }));
    builder.addCase(requestSearchEmployeeProjectCustomers.fulfilled, (state, action) => ({
      ...state,
      customers: {
        ...state.customers,
        ...action.payload,
      },
    }));
    builder.addCase(requestProjectOrders.fulfilled, (state, action) => ({
      ...state,
      orders: action.payload.records,
    }));
  },
});

const { reducer, actions } = projects;

export const { setEmployeeProjectFields } = actions;

export default reducer;
