import React from 'react';
import seLocale from 'date-fns/locale/sv';
import format from 'date-fns/format';
import addDateFns from 'date-fns/add';
import addSeconds from 'date-fns/addSeconds';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import differenceInHours from 'date-fns/differenceInHours';
import getUnixTime from 'date-fns/getUnixTime';
import getISOWeek from 'date-fns/getISOWeek';
import subDays from 'date-fns/subDays';
import subMonths from 'date-fns/subMonths';
import subWeeks from 'date-fns/subWeeks';
import getWeekOfMonthDateFns from 'date-fns/getWeekOfMonth';
import addDaysDateFns from 'date-fns/addDays';
import addMonthsDateFns from 'date-fns/addMonths';
import addWeeksDateFns from 'date-fns/addWeeks';
import startOfWeekDateFns from 'date-fns/startOfWeek';
import endOfWeekDateFns from 'date-fns/endOfWeek';
import startOfMonthDateFns from 'date-fns/startOfMonth';
import endOfMonthDateFns from 'date-fns/endOfMonth';
import getDaysInMonthDateFns from 'date-fns/getDaysInMonth';
import isSameDayDateFns from 'date-fns/isSameDay';
import isSameHourDateFns from 'date-fns/isSameHour';
import addHoursDateFns from 'date-fns/addHours';
import startOfDayDateFns from 'date-fns/startOfDay';
import getHoursDateFns from 'date-fns/getHours';
import getMinutesDateFns from 'date-fns/getMinutes';
import setDateFns from 'date-fns/set';
import addYearsFns from 'date-fns/addYears';
import isTodayDateFns from 'date-fns/isToday';
import isWeekendDateFns from 'date-fns/isWeekend';
import isSundayDateFns from 'date-fns/isSunday';
import differenceInDays from 'date-fns/differenceInDays';
import setSecondsDateFns from 'date-fns/setSeconds';
import parse from 'date-fns/parse';
import parseISO from 'date-fns/parseISO';
import differenceInYearsDateFns from 'date-fns/differenceInYears';
import isAfterDateFns from 'date-fns/isAfter';
import isDateDateFns from 'date-fns/isDate';
import isMondayDateFns from 'date-fns/isMonday';
import differenceInCalendarDaysDateFns from 'date-fns/differenceInCalendarDays';
import differenceInBusinessDaysDateFns from 'date-fns/differenceInBusinessDays';
import subMinutesDateFns from 'date-fns/subMinutes';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import eachDayOfIntervalDateFns from 'date-fns/eachDayOfInterval';
import { isNumber } from 'utils/lodash';
import startOfYear from 'date-fns/startOfYear';
import endOfYear from 'date-fns/endOfYear';
import isWithinIntervalDateFns from 'date-fns/isWithinInterval';
import getDayDateFns from 'date-fns/getDay';
import getDateDateFns from 'date-fns/getDate';
import minutesToSeconds from 'date-fns/minutesToSeconds';

const locales = {
  se: seLocale,
};

export const epochUnixTimeStamp = -19800000;
export const dateZero = new Date(epochUnixTimeStamp);

export const formatStrings = {
  defaultDate: 'yyyy MMM dd',
  defaulTime: 'HH:mm',
  dateSpaceTime: 'yyyy MMM dd HH:mm',
  punchTimeFormat: 'HH:mm:ss',
  dateDashTime: 'yyyy MMM dd - HH:mm',
  dashDateDashTime: 'yyyy-MM-dd - HH:mm',
  filtersDate: 'yyyy-MM-dd',
  weekDay: 'EEE',
  fullWeekDay: 'EEEE',
  year: 'yyyy',
  month: 'MMMM',
  monthShort: 'MMM',
  monthDate: 'MMMM dd',
  montDateShort: 'MMM dd',
  fullDate: 'dd MMMM yyyy',
  fullMonthYear: 'MMMM yyyy',
  dayMonthShort: 'EEE, dd MMM',
  dayMonthFull: 'EEE dd, MMM yyyy',
  customDate: 'yyyyMMdd',
  fullDateTime: 'yyyy MMM dd HH:mm:ss',
  monthDateYear: 'MMMM dd, yyyy',
  date: 'dd',
  invoicePeriod: 'MMM yyyy',
  monthShortDateYear: 'MMM dd, yyyy',
};

export const getDifferenceBetweenTime = (time1, time2) => {
  const [hours1, mins1] = (time1).split(':');
  const [hours2, mins2] = (time2).split(':');
  const dateTime1 = setDateFns(new Date(0), { hours: hours1, minutes: mins1 });
  const dateTime2 = setDateFns(new Date(0), { hours: hours2, minutes: mins2 });
  const minutes = differenceInMinutes(dateTime1, dateTime2) % 60 || 0;
  const hours = differenceInHours(dateTime1, dateTime2) || 0;
  const hoursString = (hours > 0 || (hours === 0 && minutes >= 0))
    ? `${hours < 10 ? `0${hours}` : hours}`
    : `${hours > -10 ? `-0${Math.abs(hours)}` : hours}`;

  const minsString = `${Math.abs(minutes) < 10 ? `0${Math.abs(minutes)}` : Math.abs(minutes)}`;

  return `${hoursString}:${minsString}`;
};

export const getDiffInHoursAndMins = (endDate, startDate) => {
  const startDateZero = startDate || dateZero;
  const minutes = Math.abs(differenceInMinutes(endDate, startDateZero)) % 60 || 0;
  const hours = Math.abs(differenceInHours(endDate, startDateZero)) || 0;
  return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
};

export const secondsToMinutesAndSeconds = (seconds, colorSeparated) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;

  return colorSeparated
    ? `${formattedMinutes}:${formattedSeconds}`
    : `${formattedMinutes} mins. ${formattedSeconds} sec.`;
};

export const getHourMinutesInUnixTime = (time) => {
  if (!time) return null;
  const [hours = 0, mins = 0] = time.split(':');
  return getUnixTime(new Date(0).setUTCHours(hours, mins));
};

export const getUnixTimeInHoursMinutes = (time) => {
  if (!isNumber(time)) return null;
  return getDiffInHoursAndMins(new Date(time * 1000), new Date(0));
};

export const getUnixTimeInHoursMinutesString = (time, formatted = true) => {
  if (!time) return null;
  const timeString = getUnixTimeInHoursMinutes(time);
  const [hours, minutes] = timeString.split(':');
  return formatted
    ? <span>{hours}h {minutes}m</span>
    : `${timeString.replace(':', 'h ')}m`;
};

export const getDifferenceBetweenLocalStrings = (time1, time2) => {
  const time = differenceInMinutes(new Date(time1), new Date(time2));
  const timeDifferenceString = getUnixTimeInHoursMinutes(minutesToSeconds(time));

  return timeDifferenceString;
};

export const formatDate = (date, formatStyle) => {
  const locale = locales[localStorage.language];
  return format(new Date(date), formatStyle, { locale });
};

export const getWeekISO = (date, options) => {
  const locale = locales[localStorage.language];
  return getISOWeek(new Date(date), options, { locale });
};

export const add = (date, options) => addDateFns(new Date(date), options);

export const subtractDays = (date, days) => subDays(new Date(date), days);

export const subtractWeeks = (date, weeks) => subWeeks(new Date(date), weeks);

export const subtractMonths = (date, months) => subMonths(new Date(date), months);

export const addMonths = (date, months) => addMonthsDateFns(new Date(date), months);

export const addWeeks = (date, weeks) => addWeeksDateFns(new Date(date), weeks);

export const addDays = (date, days) => addDaysDateFns(new Date(date), days);

export const addYears = (date, years) => addYearsFns(new Date(date), years);

export const startOfWeek = (date, options) => startOfWeekDateFns(new Date(date), { ...options, weekStartsOn: 1 });

export const endOfWeek = (date, options) => endOfWeekDateFns(new Date(date), { ...options, weekStartsOn: 1 });

export const startOfMonth = (date) => startOfMonthDateFns(new Date(date));

export const endOfMonth = (date) => endOfMonthDateFns(new Date(date));

export const getUnixTimeFromLocalString = (dateTimeString) => {
  const date = parseISO(dateTimeString);
  const hours = date.getHours();
  const seconds = hours * 3600;

  return seconds;
};

export const getDaysInMonth = (date) => getDaysInMonthDateFns(new Date(date));

export const getDay = (date) => getDayDateFns(new Date(date));

export const getDate = (date) => getDateDateFns(new Date(date));

export const getWeekOfMonthDate = (date, options = { weekStartsOn: 1 }) => (
  getWeekOfMonthDateFns(new Date(date), options)
);

export const eachDayOfInterval = (start, end) => eachDayOfIntervalDateFns({
  start: new Date(start),
  end: new Date(end),
});

export const isSameDay = (date1, date2) => isSameDayDateFns(new Date(date1), new Date(date2));

export const isSameHour = (dateTime1, dateTime2) => isSameHourDateFns(new Date(dateTime1), new Date(dateTime2));

export const isMonday = (date) => isMondayDateFns(new Date(date));

export const addHours = (dateTime, hours) => addHoursDateFns(new Date(dateTime), hours);

export const getHours = (dateTime) => getHoursDateFns(new Date(dateTime));

export const subMinutes = (dateTime, minutes) => subMinutesDateFns(new Date(dateTime), minutes);

export const getMinutes = (dateTime) => getMinutesDateFns(new Date(dateTime));

export const getDifferenceInMinutes = (endTime, startTime) => differenceInMinutes(endTime, startTime);

export const startOfDay = (date) => startOfDayDateFns(new Date(date));

export const set = (dateTime, options) => setDateFns(new Date(dateTime), options);

export const isToday = (date) => isTodayDateFns(new Date(date));

export const isWeekend = (date) => isWeekendDateFns(new Date(date));

export const isSunday = (date) => isSundayDateFns(new Date(date));

export const setSeconds = (date, seconds) => setSecondsDateFns(new Date(date), seconds);

export const differenceInYears = (date1, date2) => differenceInYearsDateFns(new Date(date1), new Date(date2));

export const isAfter = (dateLeft, dateRight) => isAfterDateFns(new Date(dateLeft), new Date(dateRight));

export const isBefore = (dateLeft, dateRight) => isAfterDateFns(new Date(dateLeft), new Date(dateRight));

export const isDate = (date) => isDateDateFns(new Date(date));

export const getStartOfYear = (date) => startOfYear(new Date(date));

export const getEndOfYear = (date) => endOfYear(new Date(date));

export const formatUnixDate = (date, formatStyle) => {
  const locale = locales[localStorage.language];
  return format(new Date(date * 1000), formatStyle, { locale });
};

export const formatDateToDuration = (date) => {
  const locale = locales[localStorage.language];
  return formatDistanceToNowStrict(new Date(date), { addSuffix: true, locale });
};

export const getTimeFromNumber = (seconds) => {
  if (Number.isNaN(seconds)) return '00:00';
  const endDate = addSeconds(dateZero, seconds);
  return getDiffInHoursAndMins(endDate);
};

export const getStandardDate = (inputDate) => {
  const date = inputDate ? new Date(inputDate) : new Date();
  return formatDate(date, 'yyyy-MM-dd');
};

export const getWeekdayName = (date) => formatDate(new Date(date), formatStrings.fullWeekDay);

export const getEnglishWeekdayName = (date) => format(new Date(date), formatStrings.fullWeekDay);

export const timeStringToFloat = (time) => {
  if (!time) return null;
  const [hoursStr, minutesStr] = time.split(/[.:]/);
  const hours = parseInt(hoursStr, 10);
  const minutes = minutesStr ? parseInt(minutesStr, 10) : 0;
  const decimalTime = hours + (minutes / 60);
  return (Math.ceil(decimalTime * 100) / 100).toFixed(2);
};


export const convertFloatToTimeString = (floatValue) => {
  let hours = Math.floor(floatValue);
  let minutes = Math.round((floatValue % 1) * 60);

  if (minutes === 60) {
    hours += 1;
    minutes = 0;
  }

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
};


export const floatToEpochTime = (time) => {
  const hours = parseInt(time, 10);
  const mins = Math.ceil(Math.abs(hours - time) * 60);
  return getUnixTime(new Date(0).setUTCHours(hours, mins));
};

export const epochTimeToFloat = (time) => timeStringToFloat(getUnixTimeInHoursMinutes(time));

export const getWeekdayOfMonth = (date) => {
  const weekNum = ['first', 'second', 'third', 'forth', 'last'];
  // here -1 is used because the day is not passed yet
  return weekNum[Math.floor(((new Date(date)).getDate() - 1) / 7)];
};

export const dayDifference = (startDate, endDate) => (
  differenceInDays(new Date(endDate), new Date(startDate))
);

export const differenceInCalendarDays = (endDate, startDate) => (
  differenceInCalendarDaysDateFns(new Date(endDate), new Date(startDate))
);

export const differenceInBusinessDays = (endDate, startDate) => (
  differenceInBusinessDaysDateFns(new Date(endDate), new Date(startDate))
);

export const getStringToDate = (dateString, formatString) => (
  parse(dateString, formatString, new Date())
);

export const convertTimeStringToLocalString = (timeString, date, formatString) => {
  const [hour, minute] = timeString.split(':');
  const currentDate = new Date(date);
  currentDate.setHours(parseInt(hour, 10), parseInt(minute, 10), 0, 0);
  return formatString
    ? format(currentDate, `yyyy-MM-dd'T'${formatString}`)
    : format(currentDate, 'yyyy-MM-dd\'T\'HH:mm:ss');
};

export { parse, parseISO };

export const convertTimeToISOString = (timeString, date) => {
  const dateStr = date ? getStandardDate(date) : '1970-01-01';
  const isoString = new Date(`${dateStr}T${timeString}`).toISOString();
  return isoString;
};

export const convertISOStringToTime = (isoString) => {
  const dateObject = new Date(isoString);
  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();
  const timeString = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  return timeString;
};

export const isOverlap = (time1Start, time1End, time2Start, time2End) => {
  const parsedTime1Start = new Date(time1Start);
  const parsedTime1End = new Date(time1End);
  const parsedTime2Start = new Date(time2Start);
  const parsedTime2End = new Date(time2End);

  return isWithinIntervalDateFns(parsedTime1Start, { start: parsedTime2Start, end: parsedTime2End })
    || isWithinIntervalDateFns(parsedTime1End, { start: parsedTime2Start, end: parsedTime2End })
    || isWithinIntervalDateFns(parsedTime2Start, { start: parsedTime1Start, end: parsedTime1End })
    || isWithinIntervalDateFns(parsedTime2End, { start: parsedTime1Start, end: parsedTime1End });
};

export const getDifferenceHoursAndMinutesBetweenTimeStamps = (date1, date2) => {
  const parsedDate1 = parseISO(date1);
  const parsedDate2 = parseISO(date2);

  const totalMinutesDifference = parsedDate2 > parsedDate1
    ? differenceInMinutes(parsedDate2, parsedDate1)
    : differenceInMinutes(parsedDate1, parsedDate2);

  const hours = Math.floor(totalMinutesDifference / 60);
  const minutes = totalMinutesDifference % 60;

  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${formattedHours}:${formattedMinutes}`;
};
