import { employeeActivitySalaryTypes, salaryPaidTypes } from 'components/EmployeeActivity/utility';
import { isEmpty } from 'utils/lodash';

export const getFormattedEmployeeActivityRequestBody = (details) => {
  const getSalaryType = () => {
    if (details.salaryPaid === salaryPaidTypes.expense) {
      return employeeActivitySalaryTypes.expense;
    }
    if (details.salaryPaid === salaryPaidTypes.time) {
      return employeeActivitySalaryTypes.timeCode;
    }
    return null;
  };

  const getEmployeeActivityItemsAttributes = () => {
    if (!isEmpty(details.employeeActivityItem) && details.employeeActivityItem.title) {
      return [
        {
          employee_activity_id: details.id,
          id: details.employeeActivityItem.id,
          title: details.employeeActivityItem.title,
          calculate_quantity_dynamically: details.employeeActivityItem.calculateQuantityDynamically,
          quantity: details.employeeActivityItem.quantity,
          vat: details.employeeActivityItem.vat,
          unit: details.employeeActivityItem.unit,
          incl_vat_per_unit: details.employeeActivityItem.inclVatPerUnit,
          excl_vat_per_unit: details.employeeActivityItem.exclVatPerUnit,
          housework_type: details.employeeActivityItem.houseworkType,
          is_housework: details.employeeActivityItem.isHousework,
          material_cost: details.employeeActivityItem.materialCost,
          account_number: details.employeeActivityItem.accountNumber,
          visma_item_id: details.employeeActivityItem.vismaItemId,
          discount_type: details.employeeActivityItem.discountType,
          discount: details.employeeActivityItem.discount,
          fortnox_id: details.employeeActivityItem.fortnoxId,
          item_id: details.employeeActivityItem.itemId,
          _destroy: details.employeeActivityItem.destroy,
        },
      ];
    }
    return undefined;
  };

  const getSalaryAttributes = () => ({
    salary_code_id: details.salaryPaid === salaryPaidTypes.expense ? details.salaryCodeId : null,
    quantity: details.quantity,
    paid_amount: details.paidAmount,
    time_code_id: details.timeCodeId,
  });

  return {
    employee_activity: {
      use_employee_time_code: details.useEmployeeTimeCode,
      title: details.title,
      comment: details.comment,
      invoiceable: details.invoiceable,
      salary_paid: details.salaryPaid,
      salary_type: getSalaryType(),
      employee_activity_items_attributes: getEmployeeActivityItemsAttributes(),
      salary_attributes: getSalaryAttributes(),
    },
  };
};

export const getFormattedEmployeeActivity = (data) => ({
  ...data.salary,
  ...data,
  totalAmount: Number(data.salary.paidAmount) * Number(data.salary.quantity),
  employeeActivityItem: !isEmpty(data.employeeActivityItems)
    ? {
      ...data.employeeActivityItems[0],
      account: { number: data.employeeActivityItems[0].accountNumber },
    }
    : {},
});
