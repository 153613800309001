import * as Yup from 'yup';
import { fm, deepTrim } from 'utils/string';

const validateBreakTimeSchema = Yup.object().shape({
  breakDuration: Yup.number().required(fm('profile_break_time_setting')).test(
    'breakDuration',
    fm('profile_break_time_smaller'),
    // eslint-disable-next-line func-names
    function (breakDuration) {
      return this.parent.breakLimit > breakDuration;
    },
  ),
});

export const validateBreakTime = async (obj) => {
  await validateBreakTimeSchema.validate(obj);
  return deepTrim(obj);
};
